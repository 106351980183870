import { useEffect, useState } from "react";
import fetchUserData, { UserData } from "../services/api/fetchUserData";

const useUserData = () => {
    const [userData, setUserData] = useState<UserData>();

    useEffect(() => {
        const setNewUserData = async (): Promise<void> => {
            const newUserData = await fetchUserData();
            setUserData(newUserData);
        };
        setNewUserData();
    }, []);
    return userData;
};

export default useUserData;
