import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Pagination.module.scss";
import {
    faAngleLeft,
    faAngleRight,
    faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    handlePageChange,
}) => {
    const isPrevPageAvailable = currentPage === 1 ? false : true;
    const isNextPageAvailable = currentPage + 1 <= totalPages ? true : false;
    const renderPageNumbers = () => {
        const pageNumbers: React.JSX.Element[] = [];
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 1 && i <= currentPage + 1)
            ) {
                pageNumbers.push(
                    <li className={styles["page-item"]} key={i}>
                        <button
                            className={`${styles["page-link"]} ${
                                currentPage === i ? styles.active : ""
                            }`}
                            disabled={currentPage === i}
                            onClick={() => handlePageChange(i)}
                        >
                            {i}
                        </button>
                    </li>,
                );
            } else if (i === currentPage - 2 || i === currentPage + 2) {
                pageNumbers.push(
                    <li
                        key={i}
                        className={`${styles["page-item"]} ${styles["ellipsis"]}`}
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </li>,
                );
            }
        }
        return pageNumbers;
    };
    return (
        <nav className={styles["pagination-container"]}>
            <ul className={styles.pagination}>
                <li className={styles["page-item"]} key={"previous"}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (isPrevPageAvailable) {
                                handlePageChange(currentPage - 1);
                            }
                        }}
                        className={styles["page-link"]}
                        disabled={!isPrevPageAvailable}
                    >
                        <span>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        <span>Previous</span>
                    </button>
                </li>
                <hr className={styles["pagination-boundary"]} />

                {renderPageNumbers()}

                <hr className={styles["pagination-boundary"]} />
                <li className={styles["page-item"]} key={"next"}>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (isNextPageAvailable) {
                                handlePageChange(currentPage + 1);
                            }
                        }}
                        className={styles["page-link"]}
                        disabled={!isNextPageAvailable}
                    >
                        <span>Next</span>
                        <span>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
