import { useState } from "react";
import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";
import styles from "./ProjectResults.module.scss";
import ProjectResultsControls from "./ProjectResultsControls";
import ProjectStudyModal from "./ProjectStudyModal";
import ProjectStudyTable from "./ProjectStudyTable";
import Pagination from "./ui/Pagination";
import { SelectedStudyDataProvider } from "../services/providers/StudyDataProvider";
import LocalSpinner from "./ui/LocalSpinner";
import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";

const ProjectResults: React.FC = () => {
    const [selectedStudyId, setSelectedStudyId] = useState<number | null>(null);
    const {
        currentPage,
        projectStudyData: { total_studies },
        setCurrentPage,
        isLoading,
    } = useProjectStudyContext();
    const totalPages = Math.max(Math.ceil(total_studies / 100), 1);

    useDisableBodyScroll(selectedStudyId !== null);
    return (
        <>
            {selectedStudyId && (
                <SelectedStudyDataProvider studyId={selectedStudyId}>
                    <ProjectStudyModal
                        handleClose={() => setSelectedStudyId(null)}
                    />
                </SelectedStudyDataProvider>
            )}
            <div className={styles["project-results"]}>
                <ProjectResultsControls />
                {isLoading ? (
                    <LocalSpinner />
                ) : (
                    <>
                        <ProjectStudyTable
                            setSelectedStudyId={setSelectedStudyId}
                        />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={(page: number) =>
                                setCurrentPage(page)
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default ProjectResults;
