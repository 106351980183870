import React from "react";
import styles from "./UploadSidebar.module.scss";
import { uploadSteps } from "../constants/generalConstansts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUploadItem } from "../services/providers/UploadItemProvider";

interface UploadSidebarProps {
    selectedUploadStep: "1" | "2" | "3" | "4";
    setSelectedUploadStep: React.Dispatch<
        React.SetStateAction<"1" | "2" | "3" | "4">
    >;
}

function getKeys<T extends object>(obj: T): Array<keyof T> {
    return Object.keys(obj) as Array<keyof T>;
}

const UploadSidebar: React.FC<UploadSidebarProps> = ({
    selectedUploadStep,
    setSelectedUploadStep,
}) => {
    const { uploadItem } = useUploadItem();
    const handleButtonClick = (newStepNumber: "1" | "2" | "3" | "4") => {
        const newStepInt = parseInt(newStepNumber);
        const selectedStepInt = parseInt(selectedUploadStep);
        const uploadStep = uploadSteps[selectedUploadStep];
        const maxStepJump =
            uploadStep.name !== "review" &&
            uploadItem &&
            uploadItem[uploadStep.name]
                ? 1
                : 0;
        if (newStepInt <= selectedStepInt + maxStepJump) {
            setSelectedUploadStep(newStepNumber);
        }
    };

    return (
        <div className={styles["upload-sidebar-wrapper"]}>
            <div className={styles["upload-sidebar"]}>
                {getKeys(uploadSteps).map((stepNumber) => {
                    const uploadStep = uploadSteps[stepNumber];
                    const greenStyle =
                        uploadStep.name !== "review" && uploadItem
                            ? uploadItem[uploadStep.name]
                                ? styles["green-change-back"]
                                : ""
                            : "";
                    const selectedStyle =
                        stepNumber === selectedUploadStep
                            ? styles.selected
                            : "";
                    return (
                        <React.Fragment key={stepNumber}>
                            <button
                                className={styles["upload-step"]}
                                onClick={() => {
                                    handleButtonClick(stepNumber);
                                }}
                            >
                                <div
                                    className={`${styles["icon-box"]} ${greenStyle} ${selectedStyle}`}
                                >
                                    <FontAwesomeIcon
                                        icon={uploadStep.icon}
                                        className={styles.icon}
                                    />
                                </div>
                                <div className={styles["text-container"]}>
                                    <p
                                        className={styles["step-number"]}
                                    >{`Step ${stepNumber}`}</p>
                                    <p className={styles["step-name"]}>
                                        {uploadStep.displayName}
                                    </p>
                                </div>
                            </button>
                            {parseInt(stepNumber) !==
                                getKeys(uploadSteps).length && (
                                <div
                                    className={`${styles.line} ${greenStyle} ${selectedStyle}`}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default UploadSidebar;
