import { useEffect, useState } from "react";
import { useSelectedStudyData } from "../services/providers/StudyDataProvider";
import styles from "./ProjectStudyCriteriaResults.module.scss";
import fetchStudyCriteriaResults, {
    StudyCriterionResult,
} from "../services/api/fetchStudyCriteriaResults";
import useProjectId from "../hooks/useProjectId";
import PercentageBar from "./ui/PercentageBar";
import LocalSpinner from "./ui/LocalSpinner";

const ProjectStudyCriteriaResults: React.FC = () => {
    const selectedStudy = useSelectedStudyData();
    const projectId = useProjectId();
    const [studyCriteriaResults, setStudyCriteriaResults] = useState<
        StudyCriterionResult[] | null
    >(null);

    useEffect(() => {
        const fetchAndSetStudyCriteriaResults = async (): Promise<void> => {
            const data = await fetchStudyCriteriaResults(
                selectedStudy.study_id,
                projectId,
            );
            setStudyCriteriaResults(data);
        };
        fetchAndSetStudyCriteriaResults();
    });
    if (studyCriteriaResults === null) {
        return <LocalSpinner />;
    }
    const topicCriterion = studyCriteriaResults.find(
        (criterion) => criterion.criterion_type === "topic",
    );
    const outcomeCriterion = studyCriteriaResults.find(
        (criterion) => criterion.criterion_type === "outcome",
    );
    const populationCriterion = studyCriteriaResults.find(
        (criterion) => criterion.criterion_type === "population",
    );
    console.log(studyCriteriaResults);
    return (
        <div className={styles["container"]}>
            {topicCriterion && (
                <div className={styles["criterion-box"]}>
                    <div className={styles.toprow}>
                        <p className={styles.type}>Topic</p>
                        <p
                            className={styles.percentage}
                        >{`${Math.round(topicCriterion.percentage * 100)}%`}</p>
                        <PercentageBar percentage={topicCriterion.percentage} />
                    </div>
                    <p className={styles.criterion}>
                        {topicCriterion.criterion}
                    </p>
                    <p
                        className={styles.reasoning}
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {topicCriterion.reasoning}
                    </p>
                </div>
            )}
            {populationCriterion && (
                <div className={styles["criterion-box"]}>
                    <div className={styles.toprow}>
                        <p className={styles.type}>Population</p>
                        <p
                            className={styles.percentage}
                        >{`${Math.round(populationCriterion.percentage * 100)}%`}</p>
                        <PercentageBar
                            percentage={populationCriterion.percentage}
                        />
                    </div>
                    <p className={styles.criterion}>
                        {populationCriterion.criterion}
                    </p>
                    <p
                        className={styles.reasoning}
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {populationCriterion.reasoning}
                    </p>
                </div>
            )}
            {outcomeCriterion && (
                <div className={styles["criterion-box"]}>
                    <div className={styles.toprow}>
                        <p className={styles.type}>Outcome</p>
                        <p
                            className={styles.percentage}
                        >{`${Math.round(outcomeCriterion.percentage * 100)}%`}</p>
                        <PercentageBar
                            percentage={outcomeCriterion.percentage}
                        />
                    </div>
                    <p className={styles.criterion}>
                        Does the study address any of the following outcomes?
                    </p>
                    <ul>
                        {JSON.parse(outcomeCriterion.criterion).map(
                            (c: string, id: number) => {
                                return <li key={id}>{c}</li>;
                            },
                        )}
                    </ul>
                    <p
                        className={styles.reasoning}
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {outcomeCriterion.reasoning}
                    </p>
                </div>
            )}
        </div>
    );
};

export default ProjectStudyCriteriaResults;
