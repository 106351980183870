import { useEffect, useState } from "react";
import fetchProjects, { Project } from "../services/api/fetchProjects";

const useFetchProjects = () => {
    const [projects, setProjects] = useState<Project[] | null>(null);

    useEffect(() => {
        const setNewUserData = async (): Promise<void> => {
            const newProjects = await fetchProjects();
            setProjects(newProjects);
        };
        setNewUserData();
    }, []);
    return projects;
};

export default useFetchProjects;
