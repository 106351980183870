import { useProjects } from "../services/providers/ProjectsProvider";
import useProjectId from "./useProjectId";

const useCurrentProject = () => {
    const projectId = useProjectId();
    const projects = useProjects();
    const currentProject = projects
        ? projects.find((project) => project.project_id === projectId)
        : undefined;
    return currentProject;
};

export default useCurrentProject;
