import styles from "./UploadForm.module.scss";
import Button from "./ui/Button";

interface UploadFormProps {
    handleSubmit: React.FormEventHandler<HTMLFormElement>;
    children: JSX.Element | JSX.Element[];
    isSubmitting: boolean;
}

const UploadForm: React.FC<UploadFormProps> = ({
    handleSubmit,
    children,
    isSubmitting,
}) => {
    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            {children}
            <Button
                text="Next"
                size="regular"
                color="blue"
                style={{ marginTop: "20px" }}
                type="submit"
                active={!isSubmitting}
            />
        </form>
    );
};

export default UploadForm;
