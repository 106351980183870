import React from "react";
import styles from "./../Sidebar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface SidebarButtonProps {
    icon: IconDefinition;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isActive: boolean;
    isRed?: boolean;
}

const SidebarButton: React.FC<SidebarButtonProps> = ({
    onClick,
    isActive,
    isRed,
    icon,
}) => {
    return (
        <button
            className={`${styles["nav-bar-button"]} ${isActive ? styles.active : ""} ${isRed ? styles.red : ""}`}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

export default SidebarButton;
