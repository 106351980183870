import UploadForm from "./UploadForm";
import { useUploadItem } from "../services/providers/UploadItemProvider";
import styles from "./UploadProjectData.module.scss";
import { ChangeEvent, useRef, useState, DragEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import postProjectData from "../services/api/postProjectData";

// const schema = z.object({
//     file: z
//         .instanceof(File)
//         .refine(
//             (file) =>
//                 ["ris"].includes(
//                     file.name.split(".").pop()?.toLowerCase() || "",
//                 ),
//             {
//                 message: "Invalid file format. Please upload a RIS file.",
//             },
//         )
//         .refine((file) => file.size <= 100 * 1024 * 1024, {
//             // 100 MB
//             message: "File size should not exceed 100MB.",
//         }),
// });

interface UploadProjectDataProps {
    handleNext: () => void;
}

const UploadProjectData: React.FC<UploadProjectDataProps> = ({
    handleNext,
}) => {
    const { uploadItem, setUploadItem } = useUploadItem();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            validateFile(file);
        }
    };

    console.log("selectedFile", selectedFile);

    const validateFile = (file: File) => {
        const fileType = file.name.split(".").pop()?.toLowerCase();
        if (!["ris"].includes(fileType || "")) {
            alert("Invalid file format. Please upload a RIS file.");
        } else if (file.size > 100 * 1024 * 1024) {
            // 10 mb
            alert("File size should not exceed 100MB");
            return;
        } else {
            setSelectedFile(file);
        }
    };

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(true);
    };

    const handleDragOut = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            validateFile(file);
        }
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = () => {
        setSelectedFile(null);
    };

    // useImperativeHandle(ref, () => ({
    //     handleUploadClick,
    // }));

    const onSubmit = async (selectedFile: File | null) => {
        if (selectedFile === null) {
            setErrorMessage("No file selected.");
        } else {
            if (selectedFile && uploadItem) {
                const newUploadItem = await postProjectData(
                    { fileName: selectedFile.name },
                    uploadItem.id,
                );
                setUploadItem(newUploadItem);
                handleNext();
            }
        }
    };

    return (
        <UploadForm
            handleSubmit={() => {
                if (selectedFile) {
                    onSubmit(selectedFile);
                } else {
                    setErrorMessage("No file selected.");
                }
            }}
            isSubmitting={false}
        >
            <>
                {selectedFile === null ? (
                    <>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            style={{ display: "none" }}
                            accept=".ris"
                        />
                        <div
                            className={`${styles["upload-box"]} ${isDragging ? styles["dragging"] : ""}`}
                            onClick={triggerFileUpload}
                            onDragOver={handleDrag}
                            onDragEnter={handleDragIn}
                            onDragLeave={handleDragOut}
                            onDrop={handleDrop}
                        >
                            <FontAwesomeIcon
                                icon={faFile}
                                className={styles["icon"]}
                            />
                            <p className={styles["upload-message"]}>
                                {"Drag your file here or browse your computer"}
                            </p>
                            <p className={styles["upload-filetype-message"]}>
                                Supports: RIS
                            </p>
                        </div>
                    </>
                ) : (
                    <div className={styles["preview-box"]}>
                        <FontAwesomeIcon
                            icon={faFile}
                            className={styles["icon"]}
                        />

                        <div className={styles["info-container"]}>
                            <p className={styles["file-name"]}>
                                {selectedFile.name}
                            </p>
                            <p className={styles["file-size"]}>
                                {`${
                                    Math.round(
                                        (selectedFile.size / (1024 * 1024) +
                                            Number.EPSILON) *
                                            100,
                                    ) / 100
                                } MB`}
                            </p>
                        </div>
                        <div
                            className={styles["delete-box"]}
                            onClick={handleDelete}
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                                className={styles["delete-icon"]}
                            ></FontAwesomeIcon>
                        </div>
                    </div>
                )}
            </>
            <>
                {errorMessage && (
                    <p className={styles["error-message"]}>{errorMessage}</p>
                )}
            </>
        </UploadForm>
    );
};

export default UploadProjectData;
