import React from "react";
import styles from "./KeywordBox.module.scss";

interface BoxProps {
    keyword: string;
}

const Header: React.FC<BoxProps> = ({ keyword }) => {
    return <p className={styles.keyword}>{keyword}</p>;
};

export default Header;
