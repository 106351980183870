import React from "react";
import styles from "./ProjectUserAvatars.module.scss";
import { UserData } from "../../services/api/fetchUserData";

interface ProjectUserAvatarsProps {
    users: UserData[];
}
// const colours = [
//     "#8CB9BD",
//     "#ECB159",
//     "#9B4444",
//     "#A3C9AA",
//     "#211C6A",
//     "#12372A",
//     "#FC6736",
//     "#F28585",
//     "#D04848",
//     "#37B5B6",
//     "#3468C0",
//     "#FAEF5D",
// ];

const colours = [
    "#264653",
    "#2A9D8F",
    "#E9C46A",
    "#F4A261",
    "#E76F51",
    "#780000",
    "#669BBC",
    "#283618",
];

const getEmailColor = (email: string) => {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
        hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Use the absolute value of hash to ensure the index is positive
    const index = Math.abs(hash) % colours.length;
    return colours[index];
};

const ProjectUserAvatars: React.FC<ProjectUserAvatarsProps> = ({ users }) => {
    return (
        <div className={styles["project-user-avatars"]}>
            {users.map((user, id) => {
                return (
                    <div
                        key={user.email}
                        className={styles["project-user-avatar"]}
                        title={user.name}
                        style={{ backgroundColor: getEmailColor(user.email) }}
                    >
                        <p>{user.name.charAt(0).toUpperCase()}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default ProjectUserAvatars;
