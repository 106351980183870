import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import UploadForm from "./UploadForm";
import { useUploadItem } from "../services/providers/UploadItemProvider";
import { faBullseye, faUsers } from "@fortawesome/free-solid-svg-icons";
import UploadProjectCriterionBox from "./UploadProjectCriterionBox";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import postProjectCriteria from "../services/api/postProjectCriteria";

const formSchema = z.object({
    studyTopic: z.string().min(1, {
        message: "Study topic is required.",
    }),
    studyPopulation: z.string().optional(),
    studyOutcomes: z
        .array(
            z.string().min(1, {
                message: "Please remove empty field.",
            }),
        )
        .max(5, "No more than five outcomes.")
        .optional(),
});

interface UploadProjectCriteriaProps {
    handleNext: () => void;
}

const UploadProjectCriteria: React.FC<UploadProjectCriteriaProps> = ({
    handleNext,
}) => {
    const { uploadItem, setUploadItem } = useUploadItem();
    // const {
    //     control,
    //     handleSubmit,
    //     formState: { errors, isSubmitting },
    // } = 0
    const methods = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: uploadItem?.project_criteria
            ? uploadItem?.project_criteria
            : {
                  studyTopic: "",
                  studyPopulation: "",
                  studyOutcomes: [],
              },
    });
    console.log("errors", methods.formState.errors.studyOutcomes?.[0]?.message);
    type FormValues = z.infer<typeof formSchema>;

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        console.log("isSubmitting", methods.formState.isSubmitting);
        console.log(data);
        if (uploadItem) {
            const newUploadItem = await postProjectCriteria(
                data,
                uploadItem.id,
            );
            setUploadItem(newUploadItem);
            handleNext();
        }
    };

    return (
        <UploadForm
            handleSubmit={methods.handleSubmit(onSubmit)}
            isSubmitting={false}
        >
            <FormProvider {...methods}>
                <Controller
                    name="studyTopic"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <UploadProjectCriterionBox
                            {...field}
                            uploadProjectCriteriaOption={{
                                name: "Study Topic",
                                question:
                                    "What is the research topic? (Intervention/Exposure, Setting)",
                                icon: faClipboard,
                                required: true,
                                multipick: false,
                            }}
                            resetValue={() => methods.resetField("studyTopic")}
                            invalid={!!methods.formState.errors.studyTopic}
                            errors={methods.formState.errors}
                            hasChanged={
                                uploadItem?.project_criteria
                                    ? uploadItem.project_criteria.studyTopic !==
                                      ""
                                    : false
                            }
                        />
                    )}
                />
                <Controller
                    name="studyOutcomes"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <UploadProjectCriterionBox
                            {...field}
                            uploadProjectCriteriaOption={{
                                name: "Study Outcomes",
                                question:
                                    "What outcome should be addressed in a study? You can list multiple.",
                                icon: faBullseye,
                                required: false,
                                multipick: true,
                            }}
                            resetValue={() =>
                                methods.resetField("studyOutcomes")
                            }
                            invalid={!!methods.formState.errors.studyOutcomes}
                            errors={methods.formState.errors}
                            hasChanged={
                                uploadItem?.project_criteria
                                    ? uploadItem.project_criteria.studyTopic
                                          .length !== 0
                                    : false
                            }
                        />
                    )}
                />
                <Controller
                    name="studyPopulation"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <UploadProjectCriterionBox
                            {...field}
                            uploadProjectCriteriaOption={{
                                name: "Study Population",
                                question:
                                    "What should the study's population be?",
                                icon: faUsers,
                                required: false,
                                multipick: false,
                            }}
                            resetValue={() =>
                                methods.resetField("studyPopulation")
                            }
                            invalid={!!methods.formState.errors.studyPopulation}
                            errors={methods.formState.errors}
                            hasChanged={
                                uploadItem?.project_criteria
                                    ? uploadItem.project_criteria
                                          .studyPopulation !== ""
                                    : false
                            }
                        />
                    )}
                />
            </FormProvider>
        </UploadForm>
    );
};

export default UploadProjectCriteria;
