import useCurrentProject from "../hooks/useCurrentProject";
import styles from "./CriteriaBox.module.scss";
import Box from "./ui/Box";

const CriteriaBox: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }
    const criteria = currentProject.criteria;
    const topicCriterion = criteria.find(
        (criterion) => criterion.criterion_type === "topic",
    );
    const outcomeCriterion = criteria.find(
        (criterion) => criterion.criterion_type === "outcome",
    );
    const populationCriterion = criteria.find(
        (criterion) => criterion.criterion_type === "population",
    );
    return (
        <Box>
            <p className={styles.title}>Criteria</p>
            <table className={styles.table}>
                <tbody>
                    {topicCriterion && (
                        <tr>
                            <td>Topic</td>
                            <td>{topicCriterion.criterion}</td>
                        </tr>
                    )}
                    {populationCriterion && (
                        <tr>
                            <td>Population</td>
                            <td>{populationCriterion.criterion}</td>
                        </tr>
                    )}
                    {outcomeCriterion && (
                        <tr>
                            <td>Outcome</td>
                            <td>
                                <>
                                    Does the study address any of the following
                                    outcomes?
                                </>
                                <ul>
                                    {JSON.parse(outcomeCriterion.criterion).map(
                                        (c: string, id: number) => {
                                            return <li key={id}>{c}</li>;
                                        },
                                    )}
                                </ul>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Box>
    );
};

export default CriteriaBox;
