import { decisionOptions } from "../constants/generalConstansts";
import { DecisionType } from "../types/generalTypes";
import { projectStudyDecisionToColor } from "../utils/statusToColorMapping";
import StatusIndicator from "./ui/StatusIndicator";
import styles from "./DecisionFilterElement.module.scss";
import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";

interface DecisionFilterElementProps {
    selectedDecisionFilters: {
        value: DecisionType[];
    };
    setSelectedDecisionFilters: React.Dispatch<
        React.SetStateAction<{
            value: DecisionType[];
        }>
    >;
    computeResultCountForDecision: (decision: DecisionType) => number;
}

const DecisionFilterElement: React.FC<DecisionFilterElementProps> = ({
    selectedDecisionFilters,
    setSelectedDecisionFilters,
    computeResultCountForDecision,
}) => {
    const { projectStudyData } = useProjectStudyContext();
    const handleCheckboxChange = (checkboxOption: DecisionType) => {
        if (selectedDecisionFilters.value.includes(checkboxOption)) {
            setSelectedDecisionFilters((prevFilters) => {
                return {
                    value: prevFilters.value.filter(
                        (option) => option !== checkboxOption,
                    ),
                };
            });
        } else {
            setSelectedDecisionFilters((prevFilters) => {
                return {
                    value: [...prevFilters.value, checkboxOption],
                };
            });
        }
    };

    if (projectStudyData) {
        return (
            <div className={styles.list}>
                {decisionOptions.map((option, id) => {
                    const isChecked = selectedDecisionFilters
                        ? selectedDecisionFilters.value.includes(option)
                        : false;
                    return (
                        <label className={styles.container} key={id}>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            <StatusIndicator
                                text={option}
                                color={projectStudyDecisionToColor(option)}
                            />
                            <p className={styles.count}>
                                {computeResultCountForDecision(option)}
                            </p>
                        </label>
                    );
                })}
            </div>
        );
    } else {
        return null;
    }
};

export default DecisionFilterElement;
