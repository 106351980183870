import axios from "axios";
import axiosInstance from "./axiosInstance";

const validateToken = async (): Promise<void> => {
    try {
        await axiosInstance.post("/validate_token");
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
    }
};

export default validateToken;
