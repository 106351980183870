import { useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import axiosInstance from "../services/api/axiosInstance";
import { useAuth } from "../services/providers/AuthProvider";

const useAxiosInterceptor = () => {
    const { setIsAuthenticated } = useAuth();

    useEffect(() => {
        const onFulfilled: (
            value: AxiosResponse<any, any>,
        ) => AxiosResponse<any, any> = (response) => {
            setIsAuthenticated(true);
            return response;
        };
        const onRejected: (error: any) => any = async (error) => {
            if (error.response.status === 400) {
                setIsAuthenticated(false);
            }
            if (error.response.status === 401) {
                setIsAuthenticated(false);
            }
            return Promise.reject(error);
        };

        const resInterceptor = axiosInstance.interceptors.response.use(
            onFulfilled,
            onRejected,
        );

        return () => {
            axios.interceptors.response.eject(resInterceptor);
        };
    }, [setIsAuthenticated]); // Only re-run if setContextState changes
};

export default useAxiosInterceptor;
