import useCurrentProject from "../hooks/useCurrentProject";
import CriteriaBox from "./CriteriaBox";
import GeneralBox from "./GeneralBox";
import styles from "./ProjectSummaryBoxes.module.scss";
import UserBox from "./UserBox";

const ProjectSummaryBoxes: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }
    return (
        <div className={styles["box-grid"]}>
            <GeneralBox />
            <CriteriaBox />
            <UserBox />
        </div>
    );
};

export default ProjectSummaryBoxes;
