import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import UploadForm from "./UploadForm";
import { TextInput, TextAreaInput } from "./ui/Input";
import postProjectInformation from "../services/api/postProjectInformation";
import { useUploadItem } from "../services/providers/UploadItemProvider";

const formSchema = z.object({
    projectName: z.string().min(1, {
        message: "Project name is required.",
    }),
    projectDescription: z.string().min(50, {
        message: "Project description must contain at least 50 characters.",
    }),
    searchString: z.string().min(1, {
        message: "Search string is required.",
    }),
});

interface UploadProjectInformationProps {
    handleNext: () => void;
}

const UploadProjectInformation: React.FC<UploadProjectInformationProps> = ({
    handleNext,
}) => {
    const { uploadItem, setUploadItem } = useUploadItem();
    console.log("uploadItem", uploadItem);
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: uploadItem?.project_information
            ? uploadItem?.project_information
            : {
                  projectName: "",
                  projectDescription: "",
                  searchString: "",
              },
    });

    const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (
        data,
    ) => {
        if (uploadItem) {
            const newUploadItem = await postProjectInformation(
                data,
                uploadItem.id,
            );
            setUploadItem(newUploadItem);
            handleNext();
        }
    };

    return (
        <UploadForm
            handleSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
        >
            <p>Title</p>
            <Controller
                name="projectName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextInput
                        {...field}
                        placeholder="E.g. Adverse Schooling Experiences"
                        invalid={!!errors.projectName}
                        errorMessage={errors.projectName?.message}
                    />
                )}
            />
            <p>Description / Objective</p>
            <Controller
                name="projectDescription"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextAreaInput
                        {...field}
                        placeholder="Min. 50 characters"
                        invalid={!!errors.projectDescription}
                        errorMessage={errors.projectDescription?.message}
                    />
                )}
            />
            <p>Search string</p>
            <Controller
                name="searchString"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <TextAreaInput
                        {...field}
                        placeholder="Enter your search string."
                        value={field.value}
                        onChange={(tags) => field.onChange(tags)}
                        invalid={!!errors.searchString}
                        errorMessage={errors.searchString?.message}
                    />
                )}
            />
        </UploadForm>
    );
};

export default UploadProjectInformation;
