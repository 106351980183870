import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import postLoginCredentials from "../services/api/postLoginCredentials";
import { useNavigate } from "react-router-dom";
import styles from "./LoginForm.module.scss";
import Button from "./ui/Button";

const schema = z.object({
    email: z.string().email(),
    password: z.string(),
});
type FormFields = z.infer<typeof schema>;

const LoginForm: React.FC = () => {
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: zodResolver(schema),
    });

    const onSubmit: SubmitHandler<FormFields> = async (data) => {
        setSubmitting(true);
        postLoginCredentials(data)
            .then(() => navigate("/"))
            .catch(() =>
                setError("root", {
                    message: "Invalid email or password.",
                }),
            )
            .finally(() => setSubmitting(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <input {...register("email")} type="text" placeholder="Email" />
            {errors.email && (
                <p className={styles.error}>{errors.email.message}</p>
            )}
            <input
                {...register("password")}
                type="password"
                placeholder="Password"
            />
            {errors.root && (
                <p className={styles.error}>{errors.root.message}</p>
            )}
            <Button
                text={submitting ? "Logging in..." : "Log in"}
                size="regular"
                color="blue"
                active={!submitting}
            />
        </form>
    );
};

export default LoginForm;
