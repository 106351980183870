import { useSelectedStudyData } from "../services/providers/StudyDataProvider";
import ProjectStudyCriteriaResults from "./ProjectStudyCriteriaResults";
import styles from "./ProjectStudyModal.module.scss";
import ProjectStudyModalProperties from "./ProjectStudyModalProperties";

interface ProjectStudyModalProps {
    handleClose: () => void;
}

const ProjectStudyModal: React.FC<ProjectStudyModalProps> = ({
    handleClose,
}) => {
    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent click event from propagating to modal-background
    };
    const selectedStudy = useSelectedStudyData();
    return (
        <div className={styles["modal-background"]} onClick={handleClose}>
            <div
                className={styles["modal-container"]}
                onClick={handleContainerClick}
            >
                <button
                    className={styles["modal-close-button"]}
                    onClick={handleClose}
                />
                <ProjectStudyModalProperties />
                <div className={styles["bottom-container"]}>
                    <p className={styles.abstract}>{selectedStudy.abstract}</p>
                    <div className={styles.seperator}></div>
                    <ProjectStudyCriteriaResults />
                </div>
            </div>
        </div>
    );
};

export default ProjectStudyModal;
