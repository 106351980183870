import React, { createContext, useContext } from "react";
import { ProjectStudy } from "../api/fetchProjectStudies";
import { useProjectStudyContext } from "./ProjectStudiesProvider";

const SelectedStudyDataProviderContext = createContext<
    ProjectStudy | undefined
>(undefined);

interface SelectedStudyDataProviderProps {
    studyId: number;
    children: React.ReactNode;
}

export const SelectedStudyDataProvider: React.FC<
    SelectedStudyDataProviderProps
> = ({ children, studyId }) => {
    const {
        projectStudyData: { project_studies },
    } = useProjectStudyContext();

    const selectedStudyData = project_studies.find(
        (study) => study.study_id === studyId,
    );

    return (
        <>
            {selectedStudyData ? (
                <SelectedStudyDataProviderContext.Provider
                    value={selectedStudyData}
                >
                    {children}
                </SelectedStudyDataProviderContext.Provider>
            ) : null}
        </>
    );
};

export const useSelectedStudyData = () => {
    const context = useContext(SelectedStudyDataProviderContext);
    if (context === undefined) {
        throw new Error(
            "useSelectedStudyData must be used within an SelectedStudyDataProvider",
        );
    }
    return context;
};
