import React, { createContext, useContext, useEffect, useState } from "react";
import fetchProjectStudies, {
    ProjectStudyData,
} from "../api/fetchProjectStudies";
import useProjectId from "../../hooks/useProjectId";
import { DecisionType } from "../../types/generalTypes";
import Spinner from "../../components/ui/Spinner";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { sortOptions } from "../../constants/generalConstansts";

export interface DecisionFilter {
    value: DecisionType[];
}

export interface MinMaxValue {
    min: number;
    max: number;
}

export interface YearFilter {
    value: MinMaxValue;
    include_unknown: boolean;
}

export interface CitationsFilter {
    value: MinMaxValue;
    include_unknown: boolean;
}

export interface StudyFilters {
    decision: null | DecisionFilter;
    year: null | YearFilter;
    citations: null | CitationsFilter;
}

export interface SortOption {
    variable: "rank" | "year" | "citations";
    order: "asc" | "desc";
    icon: IconDefinition;
    displayName: string;
}

interface ProjectStudiesContextType {
    projectStudyData: ProjectStudyData;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    appliedFilters: StudyFilters;
    setAppliedFilters: React.Dispatch<React.SetStateAction<StudyFilters>>;
    appliedSortOption: SortOption;
    setAppliedSortOption: React.Dispatch<React.SetStateAction<SortOption>>;
    appliedSearchString: string | null;
    setAppliedSearchString: React.Dispatch<React.SetStateAction<string | null>>;
    isLoading: boolean;
}

const ProjectStudiesContext = createContext<
    ProjectStudiesContextType | undefined
>(undefined);

interface ProjectStudiesProviderProps {
    children: React.ReactNode;
}

export const ProjectStudiesProvider: React.FC<ProjectStudiesProviderProps> = ({
    children,
}) => {
    const [projectStudyData, setProjectStudyData] =
        useState<ProjectStudyData>();
    const [appliedFilters, setAppliedFilters] = useState<StudyFilters>({
        decision: null,
        year: null,
        citations: null,
    });
    const [appliedSortOption, setAppliedSortOption] = useState<SortOption>(
        sortOptions[0],
    );
    const [appliedSearchString, setAppliedSearchString] = useState<
        string | null
    >(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const projectId = useProjectId();

    useEffect(() => {
        const fetchAndSetProjectStudies = async (): Promise<void> => {
            setIsLoading(true);
            const projectStudyResponse = await fetchProjectStudies(
                projectId,
                appliedFilters,
                currentPage,
                appliedSortOption,
                appliedSearchString,
            );
            setProjectStudyData(projectStudyResponse);
            setIsLoading(false);
        };
        fetchAndSetProjectStudies();
    }, [
        appliedFilters,
        currentPage,
        appliedSortOption,
        projectId,
        appliedSearchString,
    ]);

    return (
        <>
            {projectStudyData ? (
                <ProjectStudiesContext.Provider
                    value={{
                        projectStudyData,
                        currentPage,
                        setCurrentPage,
                        appliedFilters,
                        setAppliedFilters,
                        appliedSortOption,
                        setAppliedSortOption,
                        appliedSearchString,
                        setAppliedSearchString,
                        isLoading,
                    }}
                >
                    {children}
                </ProjectStudiesContext.Provider>
            ) : (
                <Spinner />
            )}
        </>
    );
};

export const useProjectStudyContext = () => {
    const context = useContext(ProjectStudiesContext);
    if (context === undefined) {
        throw new Error(
            "useProjectStudyContext must be used within an ProjectStudiesProvider",
        );
    }
    return context;
};
