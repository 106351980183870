import React from "react";
import styles from "./Header.module.scss";

interface HeaderProps {
    title: string;
    type: "main" | "sub";
    noMargin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, type, noMargin }) => {
    return (
        <>
            {type === "main" ? (
                <h1
                    className={`${styles["main-header"]} ${noMargin ? styles["no-margin"] : ""}`}
                >
                    {title}
                </h1>
            ) : (
                <h2
                    className={`${styles["main-header"]} ${noMargin ? styles["no-margin"] : ""}`}
                >
                    {title}
                </h2>
            )}
        </>
    );
};

export default Header;
