import styles from "./Panel.module.scss";

export interface PanelProps {
    label: string;
    value: string;
    subvalue?: string;
    isBlue?: boolean;
}

const Panel: React.FC<PanelProps> = ({
    isBlue = false,
    label,
    value,
    subvalue,
}) => {
    return (
        <div className={`${styles["panel"]} ${isBlue ? styles.blue : ""}`}>
            <p className={styles.label}>{label}</p>
            <p className={styles.value}>{value}</p>
            {subvalue ? <p className={styles.subvalue}>{subvalue}</p> : <br />}
        </div>
    );
};

export default Panel;
