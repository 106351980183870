import { DecisionType } from "../types/generalTypes";

import {
    IconDefinition,
    faArrowDown19,
    faArrowDown91,
    faBook,
    faBullseye,
    faList,
    faSquarePollVertical,
    faTableList,
    faUpload,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ProjectSummary from "../components/ProjectSummary";
import ProjectResults from "../components/ProjectResults";
import { ProjectStudiesProvider } from "../services/providers/ProjectStudiesProvider";
import {
    faCheckSquare,
    faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import UploadProjectInformation from "../components/UploadProjectInformation";
import UploadProjectCriteria from "../components/UploadProjectCriteria";
import UploadProjectData from "../components/UploadProjectData";

export const projectNavOptions: {
    name: string;
    icon: IconDefinition;
    path: string;
    element: JSX.Element;
}[] = [
    {
        name: "Summary",
        icon: faTableList,
        path: "/summary",
        element: <ProjectSummary />,
    },
    {
        name: "Results",
        icon: faSquarePollVertical,
        path: "/results",
        element: (
            <ProjectStudiesProvider>
                <ProjectResults />
            </ProjectStudiesProvider>
        ),
    },
];

export const decisionOptions: DecisionType[] = [
    "Included",
    "Excluded",
    "Unclear",
    "Invalid Abstract",
];

export const sortOptions: {
    variable: "rank" | "year" | "citations";
    order: "asc" | "desc";
    icon: IconDefinition;
    displayName: string;
}[] = [
    {
        variable: "rank",
        order: "asc",
        icon: faArrowDown19,
        displayName: "Rank from best match to worst",
    },
    {
        variable: "rank",
        order: "desc",
        icon: faArrowDown91,
        displayName: "Rank from worst match to best",
    },
    {
        variable: "year",
        order: "asc",
        icon: faArrowDown19,
        displayName: "Year from oldest to newest",
    },
    {
        variable: "year",
        order: "desc",
        icon: faArrowDown91,
        displayName: "Year from newest to oldest",
    },
    {
        variable: "citations",
        order: "asc",
        icon: faArrowDown19,
        displayName: "Citations from least cited to most",
    },
    {
        variable: "citations",
        order: "desc",
        icon: faArrowDown91,
        displayName: "Citations from most cited to least",
    },
];

interface UploadStep {
    name:
        | "project_information"
        | "project_criteria"
        | "project_data"
        | "review";
    displayName: string;
    description: string;
    icon: IconDefinition;
    element: (handleNext: () => void) => JSX.Element;
}

interface UploadSteps {
    "1": UploadStep;
    "2": UploadStep;
    "3": UploadStep;
    "4": UploadStep;
}

export const uploadSteps: UploadSteps = {
    "1": {
        name: "project_information",
        displayName: "Review Information",
        description:
            "Please fill in the general information about your new review.",
        icon: faBook,
        element: (handleNext: () => void) => (
            <UploadProjectInformation handleNext={handleNext} />
        ),
    },
    "2": {
        name: "project_criteria",
        displayName: "Review Criteria",
        description:
            "Please select the criteria for studies you want included into your review.",
        icon: faList,
        element: (handleNext: () => void) => (
            <UploadProjectCriteria handleNext={handleNext} />
        ),
    },
    "3": {
        name: "project_data",
        displayName: "Data Upload",
        description:
            "Please upload a RIS file that contains your reference data including the abstract.",
        icon: faUpload,
        element: (handleNext: () => void) => (
            <UploadProjectData handleNext={handleNext} />
        ),
    },
    "4": {
        name: "review",
        displayName: "Confirm",
        description:
            "Please fill in the general information about your new projects.",
        icon: faCheckSquare,
        element: (handleNext: () => void) => <></>,
    },
};

export interface UploadProjectCriteriaOption {
    name: string;
    question: string;
    icon: IconDefinition;
    required: boolean;
    multipick: boolean;
}

export const uploadProjectCriteriaOptions: UploadProjectCriteriaOption[] = [
    {
        name: "Study Topic",
        question: "What should the broad study topic be?",
        icon: faClipboard,
        required: true,
        multipick: false,
    },
    {
        name: "Study Outcome",
        question: "Which outcomes should the study address?",
        icon: faBullseye,
        required: false,
        multipick: true,
    },
    {
        name: "Study Population",
        question: "What should the study population be?",
        icon: faUsers,
        required: false,
        multipick: false,
    },
];
