import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";
import ProjectStudyCard from "./ProjectStudyCard";
import styles from "./ProjectStudyTable.module.scss";

interface ProjectStudyTableProps {
    setSelectedStudyId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ProjectStudyTable: React.FC<ProjectStudyTableProps> = ({
    setSelectedStudyId,
}) => {
    const { projectStudyData } = useProjectStudyContext();
    return (
        <div className={styles["project-study-table"]}>
            {projectStudyData.project_studies.map((projectStudy, id) => {
                return (
                    <ProjectStudyCard
                        projectStudy={projectStudy}
                        key={id}
                        handleClick={(studyId: number) =>
                            setSelectedStudyId(studyId)
                        }
                    />
                );
            })}
        </div>
    );
};

export default ProjectStudyTable;
