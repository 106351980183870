import axios from "axios";
import axiosInstance from "./axiosInstance";

export interface UploadProjectInformation {
    projectName: string;
    projectDescription: string;
    searchString: string;
}
export interface UploadProjectCriteria {
    studyTopic: string;
    studyOutcomes?: string[];
    studyPopulation?: string;
}

export interface UploadProjectData {
    fileName: string;
}

export interface UploadItem {
    id: string;
    user_id: number;
    status: "open" | "uploaded";
    timestamp: string;
    project_information: null | UploadProjectInformation;
    project_criteria: null | UploadProjectCriteria;
    project_data: null | UploadProjectData;
}

const fetchUploadItem = async (): Promise<UploadItem> => {
    try {
        const response = await axiosInstance.get<UploadItem>("/upload");
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default fetchUploadItem;
