import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";
import useAxiosInterceptor from "./hooks/useAxiosInterceptor";
import "./App.scss";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import { ProjectsProvider } from "./services/providers/ProjectsProvider";
import Upload from "./pages/Upload";
import { UploadItemProvider } from "./services/providers/UploadItemProvider";

const App: React.FC = () => {
    useAxiosInterceptor();
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route
                path="/*"
                element={
                    <RequireAuth>
                        <div className="App">
                            <Topbar />
                            <div className="sidebar-content">
                                <Sidebar />
                                <ProjectsProvider>
                                    <UploadItemProvider>
                                        <div className="content-container">
                                            <Routes>
                                                <Route
                                                    path="/projects"
                                                    element={<Projects />}
                                                />
                                                <Route
                                                    path="/upload"
                                                    element={<Upload />}
                                                />
                                                <Route
                                                    path="/projects/:id/*"
                                                    element={<Project />}
                                                />
                                                <Route
                                                    path="/"
                                                    element={
                                                        <Navigate to="/projects" />
                                                    }
                                                />
                                            </Routes>
                                        </div>
                                    </UploadItemProvider>
                                </ProjectsProvider>
                            </div>
                        </div>
                    </RequireAuth>
                }
            />
        </Routes>
    );
};

export default App;
