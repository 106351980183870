import axios from "axios";

let baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
// "https://litverse-fast-api-app.azurewebsites.net";
// if (baseURL !== "http://localhost:8000") {
//     baseURL += "/api";
// }

export const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        "X-API-Key": process.env.REACT_APP_API_KEY || "localhost",
    },
});
export default axiosInstance;
