import React from "react";
import styles from "./StatusIndicator.module.scss";
import { capitalizeFirstLetter } from "../../utils/stringFunctions";

interface StatusIndicatorProps {
    color: "green" | "yellow" | "red" | "black";
    text: string;
    size?: "small" | "medium" | "large";
    style?: React.CSSProperties;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({
    color,
    text,
    size = "medium",
    style,
}) => {
    return (
        <p
            className={`${styles["project-status-box"]} ${styles[color]} ${styles[size]}`}
            style={style}
        >
            {capitalizeFirstLetter(text)}
        </p>
    );
};

export default StatusIndicator;
