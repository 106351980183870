import React from "react";

interface HistogramProps {
    distribution: {
        value: number;
        count: number;
    }[];
    minMax: {
        min: number;
        max: number;
    };
}

const Histogram: React.FC<HistogramProps> = ({ distribution, minMax }) => {
    const getBinnedDistribution = (
        distribution: {
            value: number;
            count: number;
        }[],
    ) => {
        const maxBuckets = 50;
        if (distribution.length <= maxBuckets) {
            return distribution;
        }
        const min = Math.min(...distribution.map((item) => item.value));
        const max = Math.max(...distribution.map((item) => item.value));
        const range = max - min;
        const binSize = range / maxBuckets;
        const binnedDistribution = new Array(maxBuckets)
            .fill(0)
            .map((_, index) => ({
                value: min + binSize * (index + 0.5),
                count: 0,
            }));

        distribution.forEach((item) => {
            if (item.value >= min && item.value <= max) {
                const binIndex = Math.min(
                    Math.floor((item.value - min) / binSize),
                    maxBuckets - 1,
                );
                binnedDistribution[binIndex].count += item.count;
            }
        });

        return binnedDistribution;
    };

    const binnedDistribution = getBinnedDistribution(distribution);
    const maxCount = Math.max(...binnedDistribution.map((item) => item.count));
    const flexGrowValue = 1 / Math.max(1, binnedDistribution.length - 1);
    const isIncluded = (value: number) => {
        return minMax.min <= value && value <= minMax.max;
    };
    return (
        <div
            style={{
                display: "flex",
                height: "100px",
                alignItems: "flex-end",
                gap: "1px",
                marginBottom: "-10px",
            }}
        >
            {binnedDistribution.map((item, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: isIncluded(item.value)
                            ? "#0d2243"
                            : "rgb(216, 216, 216)",
                        height: `${item.count > 0 ? Math.max((item.count / maxCount) * 100, 2) : 0}%`,
                        flexGrow: flexGrowValue,
                        borderRadius: "2px",
                    }}
                />
            ))}
        </div>
    );
};

export default Histogram;
