import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ProjectStudyAuthors.module.scss";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

interface ProjectStudyAuthorsProps {
    authors: string[] | null;
    showAll?: boolean;
    big?: boolean;
}

const ProjectStudyAuthors: React.FC<ProjectStudyAuthorsProps> = ({
    authors,
    showAll = false,
    big = false,
}) => {
    return (
        <>
            {authors && authors.length > 0 && (
                <div
                    className={`${styles["authors"]} ${big ? styles["big"] : ""}`}
                >
                    <FontAwesomeIcon icon={faUserGroup} />
                    {!showAll ? (
                        <>
                            <p>{authors[0]}</p>
                            {authors.length > 1 && (
                                <p
                                    className={styles["other-authors"]}
                                >{`+${authors.length - 1}`}</p>
                            )}
                        </>
                    ) : (
                        <p>{authors.join(", ")}</p>
                    )}
                </div>
            )}
        </>
    );
};

export default ProjectStudyAuthors;
