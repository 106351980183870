import React from "react";
import litverse_logo from "./../images/litverse_logo.png";

interface LitverseLogoProps {
    className?: string;
}

const LitverseLogo: React.FC<LitverseLogoProps> = ({ className }) => {
    return (
        <img
            src={litverse_logo}
            alt="Litverse logo"
            className={className}
        ></img>
    );
};

export default LitverseLogo;
