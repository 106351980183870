import React from "react";
import styles from "./ProjectSideContainer.module.scss";
import ProjectNavbox from "./ProjectNavbox";
import ProjectBackButton from "./ProjectBackButton";

const ProjectSideContainer: React.FC = () => {
    return (
        <div className={styles["project-side-container-wrapper"]}>
            <div className={styles["project-side-container"]}>
                <ProjectBackButton />
                <ProjectNavbox />
            </div>
        </div>
    );
};

export default ProjectSideContainer;
