import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ProjectStudyYear.module.scss";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

interface ProjectStudyYearProps {
    year: number | null;
    big?: boolean;
}

const ProjectStudyYear: React.FC<ProjectStudyYearProps> = ({
    year,
    big = false,
}) => {
    return (
        <>
            {year && (
                <div
                    className={`${styles["year"]} ${big ? styles["big"] : ""}`}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{year}</p>
                </div>
            )}
        </>
    );
};

export default ProjectStudyYear;
