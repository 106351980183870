import styles from "./ComingSoonModal.module.scss";

interface ComingSoonModalProps {
    handleClose: () => void;
}

const ComingSoonModal: React.FC<ComingSoonModalProps> = ({ handleClose }) => {
    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent click event from propagating to modal-background
    };
    return (
        <div className={styles["modal-background"]} onClick={handleClose}>
            <div
                className={styles["modal-container"]}
                onClick={handleContainerClick}
            >
                <button
                    className={styles["modal-close-button"]}
                    onClick={handleClose}
                />
                {/* <ProjectStudyModalProperties />
                <div className={styles["bottom-container"]}>
                    <p className={styles.abstract}>{selectedStudy.abstract}</p>
                    <div className={styles.seperator}></div>
                    <ProjectStudyCriteriaResults />
                </div> */}
                <h1>Coming Soon</h1>
                <p>The live upload feature will launch soon. Keep posted!</p>
            </div>
        </div>
    );
};

export default ComingSoonModal;
