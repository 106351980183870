import React from "react";
import styles from "./Sidebar.module.scss";
import {
    faPlus,
    faBook,
    faGear,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import logout from "../services/api/logout";
import SidebarButton from "./ui/SidebarButton";

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path: string) => {
        return location.pathname === path;
    };
    const upperButtons: {
        icon: IconDefinition;
        path: string;
    }[] = [
        { icon: faPlus, path: "/upload" },
        { icon: faBook, path: "/projects" },
    ];

    const lowerButtons: {
        icon: IconDefinition;
        onClick: () => void;
    }[] = [
        { icon: faGear, onClick: () => {} },
        { icon: faBell, onClick: () => {} },
        {
            icon: faRightFromBracket,
            onClick: async () => {
                sessionStorage.clear();
                await logout();
                window.location.href = "/login";
            },
        },
    ];

    return (
        <div className={styles["sidebar-wrapper"]}>
            <div className={styles.sidebar}>
                <nav className={styles["nav-bar"]}>
                    <div className={styles["upper-container"]}>
                        {upperButtons.map((button, id) => {
                            return (
                                <SidebarButton
                                    icon={button.icon}
                                    onClick={() => {
                                        navigate(button.path);
                                    }}
                                    isActive={isActive(button.path)}
                                    key={id}
                                />
                            );
                        })}
                    </div>
                    <div className={styles["lower-container"]}>
                        {lowerButtons.map((button, id) => {
                            return (
                                <SidebarButton
                                    icon={button.icon}
                                    onClick={button.onClick}
                                    isActive={false}
                                    isRed={button.icon === faRightFromBracket}
                                    key={id}
                                />
                            );
                        })}
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;
