import React, { forwardRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
    FieldError,
    Merge,
    useFieldArray,
    useFormContext,
} from "react-hook-form";
import styles from "./UploadMultiInputList.module.scss";
import { TextInput } from "./ui/Input";

interface UploadMultiInputListProps {
    name: string; // Name to register input with react-hook-form
    errors?: Merge<FieldError, (FieldError | undefined)[]> | undefined;
    invalid: boolean;
}

const UploadMultiInputList = forwardRef<
    HTMLUListElement,
    UploadMultiInputListProps
>(({ name, invalid, errors }, ref) => {
    const { control, register } = useFormContext(); // Access form context and destruct register function
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });
    useEffect(() => {
        if (fields.length === 0) {
            append("");
        }
    }, [fields, append]);

    return (
        <>
            <ul className={styles["input-list"]} ref={ref}>
                {fields.map((item, index) => (
                    <li key={item.id}>
                        <div className={styles["input-row"]}>
                            <TextInput
                                {...register(`${name}[${index}]`)} // Directly map to the string
                                // {...register(`${name}[${index}].value`)} // Proper use of register for dynamic field array
                                defaultValue={"sdfdsf"} // Set default value
                                invalid={!!errors?.[index]}
                                errorMessage={errors?.[index]?.message}
                            />
                            {fields.length > 1 && (
                                <button
                                    onClick={() => remove(index)}
                                    className={styles["delete"]}
                                    type="button"
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        className={styles["icon"]}
                                    />
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            {fields.length < 6 && (
                <button
                    onClick={() => append("")} // Append an empty string directly
                    className={styles["add"]}
                    type="button"
                >
                    Add line
                </button>
            )}
        </>
    );
});

export default UploadMultiInputList;
