import { useEffect, useRef } from "react";

export const useOutsideClick = (callback: () => void) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                buttonRef.current &&
                !buttonRef.current.contains(event.target as Node) &&
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callback]);

    return { buttonRef, modalRef };
};
