import { useParams } from "react-router-dom";

const useProjectId = () => {
    const { id: projectId } = useParams<{ id: string }>();
    const intId = projectId ? parseInt(projectId) : undefined;
    if (intId === undefined || isNaN(intId)) {
        throw new Error("Id must be defined and not NaN");
    }
    return intId;
};

export default useProjectId;
