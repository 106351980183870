import { ProjectStudy } from "../services/api/fetchProjectStudies";
import styles from "./ProjectStudyCard.module.scss";
import StatusIndicator from "./ui/StatusIndicator";
import { projectStudyDecisionToColor } from "../utils/statusToColorMapping";
import ProjectStudyAuthors from "./ProjectStudyAuthors";
import ProjectStudyYear from "./ProjectStudyYear";
import ProjectStudyCitations from "./ProjectStudyCitations";

interface ProjectStudyCardProps {
    projectStudy: ProjectStudy;
    handleClick: (studId: number) => void;
}

const ProjectStudyCard: React.FC<ProjectStudyCardProps> = ({
    projectStudy,
    handleClick,
}) => {
    return (
        <button
            className={styles["project-study-card"]}
            onClick={() => handleClick(projectStudy.study_id)}
        >
            <div className={styles["top-container"]}>
                <div className={styles["study-properties"]}>
                    <ProjectStudyAuthors authors={projectStudy.authors} />
                    <ProjectStudyYear year={projectStudy.year} />
                    <ProjectStudyCitations citations={projectStudy.citations} />
                </div>

                <div className={styles["outcome-container"]}>
                    {projectStudy.rank && (
                        <p
                            className={styles["rank"]}
                        >{`#${projectStudy.rank}`}</p>
                    )}
                    {projectStudy.percentage !== null && (
                        <p
                            className={styles["percentage"]}
                        >{`${Math.round(projectStudy.percentage * 100)}%`}</p>
                    )}
                    <StatusIndicator
                        text={projectStudy.decision}
                        color={projectStudyDecisionToColor(
                            projectStudy.decision,
                        )}
                    />
                </div>
            </div>
            <div className={styles["bottom-container"]}>
                <p className={styles.title}>{projectStudy.title}</p>
                <p
                    className={`${styles.abstract} ${
                        !projectStudy.abstract || projectStudy.abstract === ""
                            ? styles["no-abstract"]
                            : ""
                    }`}
                >
                    {!projectStudy.abstract || projectStudy.abstract === ""
                        ? "No abstract"
                        : projectStudy.abstract}
                </p>
            </div>
        </button>
    );
};

export default ProjectStudyCard;
