import styles from "./ProjectSummary.module.scss";
import ProjectSummaryBoxes from "./ProjectSummaryBoxes";
import ProjectSummaryPanels from "./ProjectSummaryPanels";

const ProjectSummary: React.FC = () => {
    return (
        <div className={styles["project-summary"]}>
            <ProjectSummaryPanels />
            <ProjectSummaryBoxes />
        </div>
    );
};

export default ProjectSummary;
