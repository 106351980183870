import useCurrentProject from "../hooks/useCurrentProject";
import styles from "./UserBox.module.scss";
import Box from "./ui/Box";

const AdminBox: React.FC = () => {
    return <p className={styles["admin-box"]}>Admin</p>;
};

const UserBox: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }
    const users = currentProject.users;
    return (
        <Box style={{ gridColumn: "1 / span 2" }}>
            {" "}
            <p className={styles.title}>Users</p>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Organisation</th>
                        <th></th>
                    </tr>
                    {users.map((user, id) => {
                        return (
                            <tr key={id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.organisation}</td>
                                <td>{user.admin && <AdminBox />}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
};

export default UserBox;
