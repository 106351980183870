import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SortModal.module.scss";
import { faSliders, faXmark } from "@fortawesome/free-solid-svg-icons";
import { sortOptions } from "../constants/generalConstansts";
import {
    SortOption,
    useProjectStudyContext,
} from "../services/providers/ProjectStudiesProvider";

interface SortModalProps {
    closeModal: () => void;
    modalRef: React.RefObject<HTMLDivElement>;
}

const SortModal: React.FC<SortModalProps> = ({ closeModal, modalRef }) => {
    const { appliedSortOption, setAppliedSortOption, setCurrentPage } =
        useProjectStudyContext();
    const handleApplyNewSort = (sortOption: SortOption) => {
        setAppliedSortOption(sortOption);
        setCurrentPage(1);
        closeModal();
    };

    return (
        <div className={styles["sort-modal"]} ref={modalRef}>
            <div className={styles["top-container"]}>
                <div className={styles["title-container"]}>
                    <FontAwesomeIcon icon={faSliders} />
                    <p>Sort</p>
                </div>
                <button className={styles["close-button"]} onClick={closeModal}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <nav className={styles["sort-nav"]}>
                {sortOptions.map((sortOption, id) => {
                    const isApplied =
                        sortOption.variable === appliedSortOption.variable &&
                        sortOption.order === appliedSortOption.order;
                    return (
                        <button
                            key={id}
                            className={`${styles.button} ${isApplied ? styles.applied : ""}`}
                            onClick={() => {
                                if (!isApplied) {
                                    handleApplyNewSort(sortOption);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={sortOption.icon} />
                            <p>{sortOption.displayName}</p>
                        </button>
                    );
                })}
            </nav>
        </div>
    );
};

export default SortModal;
