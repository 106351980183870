import React from "react";
import styles from "./Spinner.module.scss";

const LocalSpinner: React.FC = () => {
    return (
        <div className={styles["background-local"]}>
            <span className={styles.loader}></span>
        </div>
    );
};

export default LocalSpinner;
