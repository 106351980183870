import React from "react";
import styles from "./Input.module.scss";
import "./Input.scss";
import { TagsInput, TagsInputProps } from "react-tag-input-component";

type TextInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "type" | "className" | "name"
> & {
    invalid?: boolean;
    errorMessage?: string; // Add error message prop
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({ invalid, errorMessage, ...inputProps }, ref) => {
        const className = `${styles["text-input"]} ${invalid ? styles.invalid : ""}`;
        return (
            <div style={{ width: "100%" }}>
                <input
                    {...inputProps}
                    ref={ref}
                    type="text"
                    className={className}
                />
                {invalid && errorMessage && (
                    <div className={styles["error-message"]}>
                        {errorMessage}
                    </div>
                )}
            </div>
        );
    },
);

type TextAreaProps = Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    "className" | "name"
> & {
    invalid?: boolean;
    errorMessage?: string; // Add error message prop
};

export const TextAreaInput = React.forwardRef<
    HTMLTextAreaElement,
    TextAreaProps
>(({ invalid, errorMessage, ...props }, ref) => {
    const className = `${styles["text-area-input"]} ${invalid ? styles.invalid : ""}`;
    return (
        <>
            <textarea {...props} ref={ref} className={className} />
            {invalid && errorMessage && (
                <div className={styles["error-message"]}>{errorMessage}</div>
            )}
        </>
    );
});

type CustomTagsInputProps = Omit<TagsInputProps, "classNames" | "name"> & {
    invalid?: boolean;
    errorMessage?: string; // Add error message prop
};

export const CustomTagsInput = React.forwardRef<
    HTMLTextAreaElement,
    CustomTagsInputProps
>(({ invalid, errorMessage, ...props }, ref) => {
    const className = `${styles["tags-input"]} ${invalid ? styles.invalid : ""}`;
    return (
        <>
            <TagsInput
                {...props}
                value={props.value}
                classNames={{ input: className }}
            />
            {invalid && errorMessage && (
                <div className={styles["error-message"]}>{errorMessage}</div>
            )}
        </>
    );
});
