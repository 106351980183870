import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./FilterButton.module.scss";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import FilterModal from "./FilterModal";
import {
    StudyFilters,
    useProjectStudyContext,
} from "../services/providers/ProjectStudiesProvider";
import { capitalizeFirstLetter } from "../utils/stringFunctions";
import { useOutsideClick } from "../hooks/useOutsideClick";

const FilterButton: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { appliedFilters } = useProjectStudyContext();
    const activeFilterNames: (keyof StudyFilters)[] = (
        Object.keys(appliedFilters) as (keyof StudyFilters)[]
    ).filter((key) => appliedFilters[key] !== null);
    const capitalizedActiveFilterNames = activeFilterNames
        .map((name) => capitalizeFirstLetter(name))
        .join(", ");

    const { buttonRef, modalRef } = useOutsideClick(() => {
        setShowModal(false);
    });

    return (
        <div style={{ position: "relative" }}>
            <button
                className={`${styles["filter-button"]} ${activeFilterNames.length > 0 ? styles.active : ""}`}
                onClick={() => {
                    setShowModal(!showModal);
                }}
                ref={buttonRef}
            >
                <FontAwesomeIcon icon={faSliders} />
                <p>{`Filters${activeFilterNames.length > 0 ? `: ${capitalizedActiveFilterNames}` : ""}`}</p>
            </button>
            {showModal && (
                <FilterModal
                    closeModal={() => {
                        setShowModal(false);
                    }}
                    modalRef={modalRef}
                />
            )}
        </div>
    );
};

export default FilterButton;
