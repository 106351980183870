import React, { useState } from "react";
import UploadSidebar from "../components/UploadSidebar";
import styles from "./Upload.module.scss";
import { uploadSteps } from "../constants/generalConstansts";
import Header from "../components/ui/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ComingSoonModal from "../components/ComingSoonModal";
import { useNavigate } from "react-router-dom";

const Upload: React.FC = () => {
    const [selectedUploadStep, setSelectedUploadStep] = useState<
        "1" | "2" | "3" | "4"
    >("1");
    const handleBack = () => {
        const stepInt = parseInt(selectedUploadStep);
        const adjustedStepInt = stepInt - 1;
        const stepString =
            adjustedStepInt >= 1 && adjustedStepInt <= 4
                ? adjustedStepInt.toString()
                : "1";
        setSelectedUploadStep(stepString as "1" | "2" | "3" | "4");
    };
    const handleNext = () => {
        const stepInt = parseInt(selectedUploadStep);
        const adjustedStepInt = stepInt + 1;
        const stepString =
            adjustedStepInt >= 1 && adjustedStepInt <= 4
                ? adjustedStepInt.toString()
                : "1";
        setSelectedUploadStep(stepString as "1" | "2" | "3" | "4");
    };
    const navigate = useNavigate();

    const comingSoon = true;
    return (
        <>
            <ComingSoonModal handleClose={() => navigate("/")} />
            <div className={styles["upload-container"]}>
                <UploadSidebar
                    selectedUploadStep={selectedUploadStep}
                    setSelectedUploadStep={setSelectedUploadStep}
                />
                {!comingSoon && (
                    <div className={styles["upload-content"]}>
                        <div className={styles["inner-content"]}>
                            <div className={styles["top-container"]}>
                                {selectedUploadStep !== "1" && (
                                    <button
                                        className={styles["back-button"]}
                                        onClick={() => handleBack()}
                                    >
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                        <p>Back</p>
                                    </button>
                                )}
                                <p
                                    className={styles["step-counter"]}
                                >{`Step ${selectedUploadStep}/4`}</p>
                            </div>
                            <Header
                                title={
                                    uploadSteps[selectedUploadStep].displayName
                                }
                                type="main"
                            />
                            <p className={styles.info}>
                                {uploadSteps[selectedUploadStep].description}
                            </p>
                            {uploadSteps[selectedUploadStep].element(
                                handleNext,
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Upload;
