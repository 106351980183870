import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SortButton.module.scss";
import { useState } from "react";
import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";
import SortModal from "./SortModal";
import { capitalizeFirstLetter } from "../utils/stringFunctions";
import { useOutsideClick } from "../hooks/useOutsideClick";

const SortButton: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { appliedSortOption } = useProjectStudyContext();
    const { buttonRef, modalRef } = useOutsideClick(() => {
        setShowModal(false);
    });

    return (
        <div style={{ position: "relative" }}>
            <button
                className={styles["sort-button"]}
                onClick={() => {
                    setShowModal(!showModal);
                }}
                ref={buttonRef}
            >
                <FontAwesomeIcon icon={appliedSortOption.icon} />
                <p>{`Sort by ${capitalizeFirstLetter(appliedSortOption.variable)}`}</p>
            </button>
            {showModal && (
                <SortModal
                    closeModal={() => {
                        setShowModal(false);
                    }}
                    modalRef={modalRef}
                />
            )}
        </div>
    );
};

export default SortButton;
