import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ProjectStudyCitations.module.scss";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

interface ProjectStudyCitationsProps {
    citations: number | null;
    big?: boolean;
}

const ProjectStudyCitations: React.FC<ProjectStudyCitationsProps> = ({
    citations,
    big = false,
}) => {
    return (
        <>
            {citations && (
                <div
                    className={`${styles["citations"]} ${big ? styles["big"] : ""}`}
                >
                    <FontAwesomeIcon icon={faQuoteRight} />
                    <p>{citations}</p>
                </div>
            )}
        </>
    );
};

export default ProjectStudyCitations;
