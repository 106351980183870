import axios from "axios";
import axiosInstance from "./axiosInstance";
import { UploadItem, UploadProjectData } from "./fetchUploadItem";

const postProjectData = async (
    projectData: UploadProjectData,
    projectId: string,
): Promise<UploadItem> => {
    try {
        const response = await axiosInstance.post(
            `/upload/${projectId}/project_data`,
            projectData,
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default postProjectData;
