import React from "react";
import styles from "./ProjectCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const NewProjectCard: React.FC = () => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate("/upload");
    };
    return (
        <button
            className={`${styles["project-card"]} ${styles["new"]}`}
            onClick={onClick}
        >
            <div className={styles["crete-new-container"]}>
                <FontAwesomeIcon
                    icon={faPlus}
                    className={styles["plus-icon"]}
                />
                <p className={styles["create-text"]}>Create new project</p>
            </div>
        </button>
    );
};

export default NewProjectCard;
