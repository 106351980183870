import React from "react";
import styles from "./Topbar.module.scss";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUserData from "../hooks/useUserData";

const TopbarUserInformation: React.FC = () => {
    const userData = useUserData();
    return userData ? (
        <div className={styles["user-information"]}>
            <FontAwesomeIcon icon={faUser} className={styles["user-icon"]} />
            <div>
                <p className={styles["user-name"]}>{userData.name}</p>
                <p className={styles["user-organisation"]}>
                    {userData.organisation}
                </p>
            </div>
        </div>
    ) : null;
};

export default TopbarUserInformation;
