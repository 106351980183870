import axios from "axios";
import axiosInstance from "./axiosInstance";

const logout = async (): Promise<void> => {
    try {
        await axiosInstance.get("/logout");
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default logout;
