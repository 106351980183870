import styles from "./PercentageBar.module.scss";
import chroma from "chroma-js";

const getBackgroundColor = function (percentage: number) {
    // Define the starting and ending colors.
    const startColor = "#ff0000";
    const middleColor = "yellow";
    const endColor = "#32cd32";

    if (percentage < 0.0 || percentage > 1.0) {
        throw new Error("Score must be between 0.0 and 1.0");
    }

    const colorScale = chroma
        .scale([startColor, middleColor, endColor])
        .domain([0, 0.7, 1]);
    const color = colorScale(percentage).hex();

    return color;
};

interface PercentageBarProps {
    percentage: number;
}

const PercentageBar: React.FC<PercentageBarProps> = ({ percentage }) => {
    return (
        <div className={styles["grey-bar"]}>
            <div
                className={styles["color-bar"]}
                style={{
                    width: `${percentage * 100}%`,
                    backgroundColor: getBackgroundColor(percentage),
                }}
            />
        </div>
    );
};

export default PercentageBar;
