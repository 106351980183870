import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./FilterModal.module.scss";
import {
    faGavel,
    faQuoteRight,
    faSliders,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
    IconDefinition,
    faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import {
    CitationsFilter,
    DecisionFilter,
    MinMaxValue,
    YearFilter,
    useProjectStudyContext,
} from "../services/providers/ProjectStudiesProvider";
import DecisionFilterElement from "./DecisionFilterElement";
import { decisionOptions } from "../constants/generalConstansts";
import Button from "./ui/Button";
import YearFilterElement from "./YearFilterElement";
import { DecisionType } from "../types/generalTypes";
import CitationsFilterElement from "./CitationsFilterElement";
import StatusIndicator from "./ui/StatusIndicator";

function arraysEqual<T>(a: T[], b: T[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    return sortedA.every((val, idx) => val === sortedB[idx]);
}

function minMaxEqual(a: MinMaxValue, b: MinMaxValue) {
    return a.min === b.min && a.max === b.max;
}

type FilterNamesType = "decision" | "year" | "citations";

interface FilterModalProps {
    closeModal: () => void;
    modalRef: React.RefObject<HTMLDivElement>;
}

const FilterModal: React.FC<FilterModalProps> = ({ closeModal, modalRef }) => {
    const {
        appliedFilters,
        setAppliedFilters,
        setCurrentPage,
        projectStudyData,
    } = useProjectStudyContext();
    const initialDecisionFilters = appliedFilters.decision
        ? appliedFilters.decision
        : {
              value: decisionOptions,
          };
    const [selectedDecisionFilters, setSelectedDecisionFilters] = useState<{
        value: DecisionType[];
    }>(initialDecisionFilters);
    const yearRange: { min: number; max: number } =
        projectStudyData.distinct_counts.reduce(
            (acc, item) => {
                const year = item.filter_variables.year;
                if (year && year < acc.min) acc.min = year;
                if (year && year > acc.max) acc.max = year;
                return acc;
            },
            {
                min: new Date().getFullYear(),
                max: new Date().getFullYear(),
            },
        );
    const initialYearFilters = appliedFilters.year
        ? appliedFilters.year
        : {
              value: yearRange,
              include_unknown: true,
          };
    const [selectedYearFilters, setSelectedYearFilters] = useState<{
        value: MinMaxValue;
        include_unknown: boolean;
    }>(initialYearFilters);
    const citationsRange: { min: number; max: number } =
        projectStudyData.distinct_counts.reduce(
            (acc, item) => {
                const citations = item.filter_variables.citations;
                if (citations && citations < acc.min) acc.min = citations;
                if (citations && citations > acc.max) acc.max = citations;
                return acc;
            },
            {
                min: 0,
                max: 0,
            },
        );
    const initialCitationsFilters = appliedFilters.citations
        ? appliedFilters.citations
        : {
              value: citationsRange,
              include_unknown: true,
          };
    const [selectedCitationsFilters, setSelectedCitationsFilters] = useState<{
        value: MinMaxValue;
        include_unknown: boolean;
    }>(initialCitationsFilters);

    const computeResultCount = ({
        decisionFilter = selectedDecisionFilters,
        yearFilter = selectedYearFilters,
        citationsFilter = selectedCitationsFilters,
    }: {
        decisionFilter?: DecisionFilter;
        yearFilter?: YearFilter;
        citationsFilter?: CitationsFilter;
    }): number => {
        return projectStudyData.distinct_counts
            .filter(
                (item) =>
                    (decisionFilter.value
                        ? decisionFilter.value.includes(
                              item.filter_variables.decision,
                          )
                        : true) &&
                    (item.filter_variables.year
                        ? yearFilter.value
                            ? yearFilter.value.min <=
                                  item.filter_variables.year &&
                              yearFilter.value.max >= item.filter_variables.year
                            : true
                        : yearFilter.include_unknown) &&
                    (item.filter_variables.citations
                        ? citationsFilter.value
                            ? citationsFilter.value.min <=
                                  item.filter_variables.citations &&
                              citationsFilter.value.max >=
                                  item.filter_variables.citations
                            : true
                        : citationsFilter.include_unknown),
            )
            .reduce((sum, current) => sum + current.count, 0);
    };
    const computeResultCountForDecision = (decision: DecisionType) => {
        return computeResultCount({ decisionFilter: { value: [decision] } });
    };
    const computeResultCountForYear = (year: number) => {
        return computeResultCount({
            yearFilter: {
                value: { min: year, max: year },
                include_unknown: false,
            },
        });
    };
    const computeResultCountForCitations = (citationCount: number) => {
        return computeResultCount({
            citationsFilter: {
                value: { min: citationCount, max: citationCount },
                include_unknown: false,
            },
        });
    };
    const yearDistribution = Array.from(
        { length: yearRange.max - yearRange.min + 1 },
        (_, index) => {
            return {
                value: yearRange.min + index,
                count: computeResultCountForYear(yearRange.min + index),
            };
        },
    );

    const citationsDistribution = Array.from(
        { length: citationsRange.max - citationsRange.min + 1 },
        (_, index) => {
            return {
                value: citationsRange.min + index,
                count: computeResultCountForCitations(
                    citationsRange.min + index,
                ),
            };
        },
    );

    const filterTypes: {
        name: FilterNamesType;
        displayName: string;
        icon: IconDefinition;
        element: JSX.Element | null;
    }[] = [
        {
            name: "decision",
            displayName: "Decision",
            icon: faGavel,
            element: (
                <DecisionFilterElement
                    selectedDecisionFilters={selectedDecisionFilters}
                    setSelectedDecisionFilters={setSelectedDecisionFilters}
                    computeResultCountForDecision={
                        computeResultCountForDecision
                    }
                />
            ),
        },
        {
            name: "year",
            displayName: "Year",
            icon: faCalendar,
            element: (
                <YearFilterElement
                    selectedYearFilters={selectedYearFilters}
                    setSelectedYearFilters={setSelectedYearFilters}
                    yearRange={yearRange}
                    yearDistribution={yearDistribution}
                />
            ),
        },
        {
            name: "citations",
            displayName: "Citations",
            icon: faQuoteRight,
            element: (
                <CitationsFilterElement
                    selectedCitationsFilters={selectedCitationsFilters}
                    setSelectedCitationsFilters={setSelectedCitationsFilters}
                    citationsRange={citationsRange}
                    citationsDistribution={citationsDistribution}
                />
            ),
        },
    ];

    const [selectedFilterType, setSelectedFilterType] =
        useState<FilterNamesType>(filterTypes[0].name);
    const filterElement = filterTypes.find(
        (filterType) => filterType.name === selectedFilterType,
    )?.element;
    const totalResultCount = computeResultCount({});

    const filterStatus = (filterName: "decision" | "year" | "citations") => {
        type statusType = "active" | "selected" | null;
        let status: statusType = null;
        if (appliedFilters[filterName]) {
            status = "active";
        }
        if (filterName === "decision") {
            if (
                !arraysEqual(
                    initialDecisionFilters.value,
                    selectedDecisionFilters.value,
                )
            ) {
                status = "selected";
            }
        } else if (filterName === "year") {
            if (
                !(
                    minMaxEqual(
                        initialYearFilters.value,
                        selectedYearFilters.value,
                    ) &&
                    initialYearFilters.include_unknown ===
                        selectedYearFilters.include_unknown
                )
            ) {
                status = "selected";
            }
        } else if (filterName === "citations") {
            if (
                !(
                    minMaxEqual(
                        initialCitationsFilters.value,
                        selectedCitationsFilters.value,
                    ) &&
                    initialCitationsFilters.include_unknown ===
                        selectedCitationsFilters.include_unknown
                )
            ) {
                status = "selected";
            }
        }
        return status;
    };

    const handleApply = () => {
        setAppliedFilters({
            decision: arraysEqual(
                decisionOptions,
                selectedDecisionFilters.value,
            )
                ? null
                : selectedDecisionFilters,
            year:
                minMaxEqual(yearRange, selectedYearFilters.value) &&
                true === selectedYearFilters.include_unknown
                    ? null
                    : selectedYearFilters,
            citations:
                minMaxEqual(citationsRange, selectedCitationsFilters.value) &&
                true === selectedCitationsFilters.include_unknown
                    ? null
                    : selectedCitationsFilters,
        });
        setCurrentPage(1);
        closeModal();
    };
    const handleClear = () => {
        if (
            !(
                appliedFilters.citations === null &&
                appliedFilters.year === null &&
                appliedFilters.decision === null
            )
        ) {
            setAppliedFilters({
                decision: null,
                year: null,
                citations: null,
            });
            setCurrentPage(1);
        }
        closeModal();
    };

    return (
        <div className={styles["filter-modal"]} ref={modalRef}>
            <div className={styles["top-container"]}>
                <div className={styles["title-container"]}>
                    <FontAwesomeIcon icon={faSliders} />
                    <p>Filters</p>
                </div>
                <button className={styles["close-button"]} onClick={closeModal}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <div className={styles["filter-switch-container"]}>
                {filterTypes.map((filterType, id) => {
                    const status = filterStatus(filterType.name);
                    return (
                        <div
                            className={`${styles["filter-switch-option"]} ${filterType.name === selectedFilterType ? styles["selected"] : ""}`}
                            key={id}
                            onClick={() =>
                                setSelectedFilterType(filterType.name)
                            }
                        >
                            <FontAwesomeIcon icon={filterType.icon} />
                            <div className={styles["filter-name-wrapper"]}>
                                <p className={styles["filter-name"]}>
                                    {filterType.displayName}
                                </p>
                                {status && (
                                    <div className={styles["status-container"]}>
                                        {status === "active" ? (
                                            <StatusIndicator
                                                color="green"
                                                text="Active"
                                                size="small"
                                            />
                                        ) : (
                                            <StatusIndicator
                                                color="yellow"
                                                text="Selected"
                                                size="small"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            {filterType.name === selectedFilterType && (
                                <div className={styles.line} />
                            )}
                        </div>
                    );
                })}
            </div>
            {filterElement}
            <div className={styles["button-container"]}>
                <Button
                    text="Clear"
                    active={true}
                    size="regular"
                    color="white"
                    handleClick={() => handleClear()}
                />
                <Button
                    text={`Show ${totalResultCount.toLocaleString()} results`}
                    active={
                        totalResultCount > 0 &&
                        (filterStatus("decision") === "selected" ||
                            filterStatus("year") === "selected" ||
                            filterStatus("citations") === "selected")
                    }
                    size="regular"
                    color="blue"
                    style={{ marginLeft: "auto" }}
                    handleClick={() => handleApply()}
                />
            </div>
        </div>
    );
};

export default FilterModal;
