import axios from "axios";
import axiosInstance from "./axiosInstance";
import { UserData } from "./fetchUserData";

export interface ProjectCriterion {
    criterion_id: number;
    criterion_type: "topic" | "outcome" | "population";
    criterion: string;
}

interface ProjectResults {
    successful: boolean;
    runtime: number;
    study_count: number;
    incomplete_abstracts: number;
    included_studies: number;
}

interface UserWithProjectRoles extends UserData {
    admin: boolean;
    creator: boolean;
}

export interface Project {
    project_id: number;
    name: string;
    description: string;
    keywords: string[];
    users: UserWithProjectRoles[];
    date_created: string;
    criteria: ProjectCriterion[];
    results: ProjectResults;
}

const fetchProjects = async (): Promise<Project[]> => {
    try {
        const response = await axiosInstance.get<Project[]>("/projects");
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default fetchProjects;
