import React from "react";
import styles from "./ProjectNavButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface SidebarButtonProps {
    name: string;
    icon: IconDefinition;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isActive: boolean;
}

const ProjectNavButton: React.FC<SidebarButtonProps> = ({
    onClick,
    isActive,
    icon,
    name,
}) => {
    return (
        <button
            className={`${styles["nav-bar-button"]} ${isActive ? styles.active : ""}`}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} />
            <p>{name}</p>
        </button>
    );
};

export default ProjectNavButton;
