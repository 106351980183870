import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const MUISlider = styled(Slider)(({ theme }) => ({
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
        height: 27,
        width: 27,
        backgroundColor: "#fff",
        border: "1px solid currentColor",
        "&:hover": {
            boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
        },
        "& .slider-bar": {
            height: 9,
            width: 1,
            backgroundColor: "currentColor",
            marginLeft: 1,
            marginRight: 1,
        },
    },
    "& .MuiSlider-track": {
        height: 3,
    },
    "& .MuiSlider-rail": {
        color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
        opacity: theme.palette.mode === "dark" ? undefined : 1,
        height: 3,
    },
}));

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

function ThumbComponent(props: ThumbComponentProps) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className="slider-bar" />
            <span className="slider-bar" />
            <span className="slider-bar" />
        </SliderThumb>
    );
}

interface CustomisedSliderProps {
    minRange: number;
    maxRange: number;
    minValue: number;
    maxValue: number;
    handleChange: (
        event: Event,
        value: number | number[],
        activeThumb: number,
    ) => void;
}

const CustomisedSlider: React.FC<CustomisedSliderProps> = ({
    minValue,
    maxValue,
    minRange,
    maxRange,
    handleChange,
}) => {
    return (
        <MUISlider
            slots={{ thumb: ThumbComponent }}
            onChange={handleChange}
            disableSwap
            value={[minValue, maxValue]}
            min={minRange}
            max={maxRange}
        />
    );
};

export default CustomisedSlider;
