import React from "react";
import styles from "./Box.module.scss";

interface BoxProps {
    padding?: number;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const Header: React.FC<BoxProps> = ({ padding = 20, children, style }) => {
    return (
        <div
            className={styles.box}
            style={{ padding: `${padding}px`, ...style }}
        >
            {children}
        </div>
    );
};

export default Header;
