import { useState } from "react";
import CustomisedSlider from "./ui/CustomisedSlider";
import styles from "./CitationsFilterElement.module.scss";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Histogram from "./Histogram";
import { MinMaxValue } from "../services/providers/ProjectStudiesProvider";

const CustomTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#0d2243",
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#0d2243",
            borderWidth: "1px",
        },
        "& fieldset": {
            borderColor: "#dcdcdc",
            borderRadius: "10px",
        },
    },
});

interface SliderInput {
    min: { value: number | ""; error: boolean };
    max: { value: number | ""; error: boolean };
}

interface CitationsFilterElementProps {
    citationsRange: MinMaxValue;
    citationsDistribution: {
        value: number;
        count: number;
    }[];
    selectedCitationsFilters: {
        value: MinMaxValue;
        include_unknown: boolean;
    };
    setSelectedCitationsFilters: React.Dispatch<
        React.SetStateAction<{
            value: MinMaxValue;
            include_unknown: boolean;
        }>
    >;
}

const CitationsFilterElement: React.FC<CitationsFilterElementProps> = ({
    citationsRange,
    citationsDistribution,
    selectedCitationsFilters,
    setSelectedCitationsFilters,
}) => {
    const [inputFieldValue, setInputFieldValue] = useState<SliderInput>({
        min: {
            value: selectedCitationsFilters.value.min,
            error: false,
        },
        max: {
            value: selectedCitationsFilters.value.max,
            error: false,
        },
    });

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: "min" | "max",
    ) => {
        const value =
            event.target.value === "" ? "" : parseInt(event.target.value);
        if (value === "" || !isNaN(value)) {
            if (type === "min") {
                if (
                    value !== "" &&
                    value >= citationsRange.min &&
                    value <= selectedCitationsFilters.value.max
                ) {
                    setSelectedCitationsFilters({
                        ...selectedCitationsFilters,
                        value: {
                            min: value,
                            max: selectedCitationsFilters.value.max,
                        },
                    });
                    setInputFieldValue({
                        ...inputFieldValue,
                        min: { value: value, error: false },
                    });
                } else {
                    setInputFieldValue({
                        ...inputFieldValue,
                        min: { value: value, error: true },
                    });
                }
            } else {
                setInputFieldValue({
                    ...inputFieldValue,
                    max: { value: value, error: false },
                });
                if (
                    value !== "" &&
                    value <= citationsRange.max &&
                    value >= selectedCitationsFilters.value.min
                ) {
                    setSelectedCitationsFilters({
                        ...selectedCitationsFilters,
                        value: {
                            min: selectedCitationsFilters.value.min,
                            max: value,
                        },
                    });
                } else {
                    setInputFieldValue({
                        ...inputFieldValue,
                        max: { value: value, error: true },
                    });
                }
            }
        }
    };
    const handleBlur = (type: "min" | "max") => {
        if (type === "min") {
            if (inputFieldValue.min.error) {
                setInputFieldValue({
                    ...inputFieldValue,
                    min: {
                        value: selectedCitationsFilters.value.min,
                        error: false,
                    },
                });
            }
        } else {
            if (inputFieldValue.max.error) {
                setInputFieldValue({
                    ...inputFieldValue,
                    max: {
                        value: selectedCitationsFilters.value.max,
                        error: false,
                    },
                });
            }
        }
    };
    const handleCheckboxClick = () => {
        setSelectedCitationsFilters({
            ...selectedCitationsFilters,
            include_unknown: !selectedCitationsFilters.include_unknown,
        });
    };
    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setSelectedCitationsFilters({
                ...selectedCitationsFilters,
                value: {
                    min: newValue[0],
                    max: selectedCitationsFilters.value.max,
                },
            });
            setInputFieldValue({
                ...inputFieldValue,
                min: { value: newValue[0], error: false },
            });
        } else {
            setSelectedCitationsFilters({
                ...selectedCitationsFilters,
                value: {
                    min: selectedCitationsFilters.value.min,
                    max: newValue[1],
                },
            });
            setInputFieldValue({
                ...inputFieldValue,
                max: { value: newValue[1], error: false },
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles["slider-chart-container"]}>
                <Histogram
                    distribution={citationsDistribution}
                    minMax={{
                        min: selectedCitationsFilters.value.min,
                        max: selectedCitationsFilters.value.max,
                    }}
                />
                <CustomisedSlider
                    minValue={selectedCitationsFilters.value.min}
                    maxValue={selectedCitationsFilters.value.max}
                    minRange={citationsRange.min}
                    maxRange={citationsRange.max}
                    handleChange={handleChange}
                />
            </div>
            <div className={styles["text-fields"]}>
                <CustomTextField
                    error={inputFieldValue.min.error}
                    id="outlined-error"
                    label="Min"
                    value={inputFieldValue.min.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, "min")
                    }
                    size="small"
                    onBlur={() => {
                        handleBlur("min");
                    }}
                />
                <CustomTextField
                    error={inputFieldValue.max.error}
                    id="outlined-error"
                    label="Max"
                    value={inputFieldValue.max.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, "max")
                    }
                    size="small"
                    onBlur={() => {
                        handleBlur("max");
                    }}
                />
            </div>
            <label className={styles.checkbox}>
                <input
                    type="checkbox"
                    checked={selectedCitationsFilters.include_unknown}
                    onChange={() => handleCheckboxClick()}
                />
                <p className={styles.count}>
                    Include studies with unknown citations
                </p>
            </label>
        </div>
    );
};

export default CitationsFilterElement;
