import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProjectSideContainer from "../components/ProjectSideContainer";
import styles from "./Project.module.scss";
import ProjectTitleContainer from "../components/ProjectTitleContainer";
import { projectNavOptions } from "../constants/generalConstansts";

const Project: React.FC = () => {
    return (
        <div className={styles["project-container"]}>
            <ProjectSideContainer />
            <div className={styles["project-content-container"]}>
                <ProjectTitleContainer />
                <Routes>
                    {projectNavOptions.map((navOption, id) => {
                        return (
                            <Route
                                path={navOption.path}
                                element={navOption.element}
                                key={id}
                            />
                        );
                    })}
                    <Route path="/" element={<Navigate to="summary" />} />
                </Routes>
            </div>
        </div>
    );
};

export default Project;
