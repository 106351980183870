import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";
import styles from "./SearchBar.module.scss";

const SearchBar: React.FC = () => {
    const { setAppliedSearchString } = useProjectStudyContext();
    const [inputValue, setInputValue] = useState("");

    const debouncedSetSearchString = debounce((value) => {
        setAppliedSearchString(value !== "" ? value : null);
    }, 500);

    useEffect(() => {
        debouncedSetSearchString(inputValue);
        return () => {
            debouncedSetSearchString.cancel();
        };
    }, [inputValue, debouncedSetSearchString]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="details-results-controls">
            <div className={styles["search-container"]}>
                <input
                    type="text"
                    className={styles["search-input"]}
                    placeholder="Search ..."
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default SearchBar;
