import useCurrentProject from "../hooks/useCurrentProject";
import Box from "./ui/Box";
import styles from "./GeneralBox.module.scss";
import KeywordBox from "./KeywordBox";

const GeneralBox: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }
    return (
        <Box>
            <p className={styles.title}>General</p>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{currentProject.name}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{currentProject.description}</td>
                    </tr>
                    <tr>
                        <td>Keywords</td>
                        <td>
                            <div className={styles["keyword-wrapper"]}>
                                {currentProject.keywords.map((keyword, id) => {
                                    return (
                                        <KeywordBox
                                            keyword={keyword}
                                            key={id}
                                        />
                                    );
                                })}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

export default GeneralBox;
