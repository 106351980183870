import axios from "axios";
import axiosInstance from "./axiosInstance";
import { UploadItem, UploadProjectInformation } from "./fetchUploadItem";

const postProjectInformation = async (
    projectInformation: UploadProjectInformation,
    projectId: string,
): Promise<UploadItem> => {
    try {
        const response = await axiosInstance.post(
            `/upload/${projectId}/project_information`,
            projectInformation,
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default postProjectInformation;
