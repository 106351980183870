import axios from "axios";
import axiosInstance from "./axiosInstance";

export interface UserData {
    email: string;
    name: string;
    organisation: string;
}

const fetchUserData = async (): Promise<UserData> => {
    try {
        const response = await axiosInstance.get<UserData>("/user_data");
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default fetchUserData;
