import React, { createContext, useContext, useEffect, useState } from "react";
import fetchUploadItem, { UploadItem } from "../api/fetchUploadItem";

const UploadItemContext = createContext<
    | {
          uploadItem: UploadItem | null;
          setUploadItem: React.Dispatch<
              React.SetStateAction<UploadItem | null>
          >;
      }
    | undefined
>(undefined);

interface UploadItemProviderProps {
    children: React.ReactNode;
}

export const UploadItemProvider: React.FC<UploadItemProviderProps> = ({
    children,
}) => {
    const [uploadItem, setUploadItem] = useState<UploadItem | null>(null);

    useEffect(() => {
        const setNewUploadItemData = async (): Promise<void> => {
            const newUploadItem = await fetchUploadItem();
            setUploadItem(newUploadItem);
        };
        setNewUploadItemData();
    }, []);

    return (
        <UploadItemContext.Provider
            value={{ uploadItem: uploadItem, setUploadItem: setUploadItem }}
        >
            {children}
        </UploadItemContext.Provider>
    );
};

export const useUploadItem = () => {
    const context = useContext(UploadItemContext);
    if (context === undefined) {
        throw new Error("useProjects must be used within an ProjectsProvider");
    }
    return context;
};
