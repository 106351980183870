import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../services/providers/AuthProvider";
import Spinner from "./ui/Spinner";

interface RequireAuthProps {
    children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const { isAuthenticated } = useAuth();
    if (isAuthenticated === null) {
        return <Spinner />;
    }
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return children;
};
export default RequireAuth;
