import React from "react";
import ProjectGallery from "../components/ProjectGallery";
import Header from "../components/ui/Header";

const Projects: React.FC = () => {
    return (
        <>
            <Header title="Project Library" type="main" />
            <ProjectGallery />
        </>
    );
};

export default Projects;
