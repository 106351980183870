import styles from "./Button.module.scss";

interface ButtonProps {
    text: string;
    active?: boolean;
    size: "large" | "regular" | "small";
    color: "white" | "blue";
    handleClick?: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
    type?: "button" | "submit" | "reset" | undefined;
}

const Button: React.FC<ButtonProps> = ({
    text,
    size,
    active = true,
    color,
    style,
    handleClick = () => {},
    type = undefined,
}) => {
    return (
        <button
            style={style}
            className={`${styles.button} ${active ? styles.active : ""} ${styles[color]} ${styles[size]}`}
            onClick={active ? handleClick : undefined}
            type={type}
        >
            <p>{text}</p>
        </button>
    );
};

export default Button;
