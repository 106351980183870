import Header from "./ui/Header";
import styles from "./ProjectTitleContainer.module.scss";
import StatusIndicator from "./ui/StatusIndicator";
import useCurrentProject from "../hooks/useCurrentProject";
import { projectStatusToColor } from "../utils/statusToColorMapping";

const ProjectTitleContainer: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }
    const projectTitle = currentProject.name;
    return (
        <div className={styles["title-container"]}>
            <div className={styles["upper-container"]}>
                <Header title={projectTitle} type="sub" noMargin={true} />
                <StatusIndicator
                    text={"completed"}
                    color={projectStatusToColor("completed")}
                    style={{ marginLeft: "auto" }}
                    size="large"
                />
            </div>
            <hr />
        </div>
    );
};

export default ProjectTitleContainer;
