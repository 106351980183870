import useCurrentProject from "../hooks/useCurrentProject";
import styles from "./ProjectSummaryPanels.module.scss";
import Panel, { PanelProps } from "./ui/Panel";
import Spinner from "./ui/Spinner";

// function formatTime(seconds: number): string {
//     const roundedSeconds = Math.round(seconds);
//     const minutes = Math.floor(roundedSeconds / 60);
//     const remainingSeconds = Math.round(roundedSeconds % 60);
//     return `${minutes}min ${remainingSeconds}s`;
// }

const ProjectSummaryPanels: React.FC = () => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return <Spinner />;
    }
    const includedPercentage =
        (currentProject.results.included_studies /
            currentProject.results.study_count) *
        100;
    const includedStudiesPanelProps: PanelProps = {
        label: "Included studies",
        value: currentProject.results.included_studies.toLocaleString(),
        subvalue: `${includedPercentage.toFixed(1)}% of input studies`,
        isBlue: true,
    };
    const incompletePercentage =
        (currentProject.results.incomplete_abstracts /
            currentProject.results.study_count) *
        100;
    const incompleteAbstractsPanelProps: PanelProps = {
        label: "Incomplete abstracts",
        value: currentProject.results.incomplete_abstracts.toLocaleString(),
        subvalue: `${incompletePercentage.toFixed(1)}% of input studies`,
    };
    const inputStudiesPanelProps: PanelProps = {
        label: "Input studies",
        value: currentProject.results.study_count.toLocaleString(),
    };
    const creator = currentProject.users.find((user) => user.creator)?.name;
    const creationDatePanelProps: PanelProps = {
        label: "Creation date",
        value: currentProject.date_created,
        subvalue: `By ${creator}`,
    };
    // const runTimePanelProps: PanelProps = {
    //     label: "Run time",
    //     value: formatTime(currentProject.results.runtime),
    // };
    return (
        <div className={styles["project-summary-panels"]}>
            <Panel {...includedStudiesPanelProps} />
            <div />
            <Panel {...incompleteAbstractsPanelProps} />
            <div className={styles.border} />
            <Panel {...inputStudiesPanelProps} />
            <div className={styles.border} />
            <Panel {...creationDatePanelProps} />
        </div>
    );
};

export default ProjectSummaryPanels;
