import React from "react";
import styles from "./ProjectBackButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ProjectBackButton: React.FC = () => {
    const navigate = useNavigate();
    return (
        <button
            className={styles["back-button"]}
            onClick={() => {
                navigate("/projects");
            }}
        >
            <FontAwesomeIcon icon={faAngleLeft} />
            <p>Back to library</p>
        </button>
    );
};

export default ProjectBackButton;
