import React, { createContext, useContext } from "react";
import { Project } from "../api/fetchProjects";
import useFetchProjects from "../../hooks/useFetchProjects";

const ProjectsContext = createContext<Project[] | null>(null);

interface ProjectsProviderProps {
    children: React.ReactNode;
}

export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({
    children,
}) => {
    const projects = useFetchProjects();

    return (
        <ProjectsContext.Provider value={projects}>
            {children}
        </ProjectsContext.Provider>
    );
};

export const useProjects = () => {
    const context = useContext(ProjectsContext);
    if (context === undefined) {
        throw new Error("useProjects must be used within an ProjectsProvider");
    }
    return context;
};
