import React from "react";
import LoginForm from "../components/LoginForm";
import { useAuth } from "../services/providers/AuthProvider";
import { Navigate } from "react-router-dom";
import LitverseLogo from "../components/LitverseLogo";
import styles from "./Login.module.scss";

const Login: React.FC = () => {
    const { isAuthenticated } = useAuth();
    if (isAuthenticated) {
        return <Navigate to="/" />;
    }
    return (
        <div className={styles.background}>
            <div className={styles["login-container"]}>
                <LitverseLogo className={styles.logo} />
                <LoginForm />
            </div>
        </div>
    );
};

export default Login;
