import styles from "./ProjectResultsControls.module.scss";
import SearchBar from "./SearchBar";
import FilterButton from "./FilterButton";
import SortButton from "./SortButton";
import { useProjectStudyContext } from "../services/providers/ProjectStudiesProvider";

const ProjectResultsControls: React.FC = () => {
    const { projectStudyData, currentPage } = useProjectStudyContext();
    const minStudyNumber = 100 * (currentPage - 1) + 1;
    const maxStudyNumber = Math.min(
        100 * currentPage,
        projectStudyData.total_studies,
    );
    return (
        <div className={styles["project-results-controls"]}>
            <p className={styles["studies-shown-text"]}>
                {projectStudyData.total_studies > 0
                    ? `Studies ${minStudyNumber} – ${maxStudyNumber} out of ${projectStudyData.total_studies.toLocaleString()}`
                    : "No results found"}
            </p>
            <SortButton />
            <FilterButton />
            <SearchBar />
        </div>
    );
};

export default ProjectResultsControls;
