import React from "react";
import styles from "./ProjectCard.module.scss";
import ProjectUserAvatars from "./ProjectUserAvatars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Project } from "./../../services/api/fetchProjects";
import { useNavigate } from "react-router-dom";
import StatusIndicator from "./StatusIndicator";
import { projectStatusToColor } from "../../utils/statusToColorMapping";

interface ProjectCardProps {
    project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const navigate = useNavigate();
    const onClick = (id: number) => {
        return () => navigate(`/projects/${id}/summary`);
    };

    return (
        <button
            className={styles["project-card"]}
            onClick={onClick(project.project_id)}
        >
            <div className={styles["project-card-top"]}>
                <StatusIndicator
                    text={"completed"}
                    color={projectStatusToColor("completed")}
                />
            </div>
            <p className={styles["project-title"]}>{project.name}</p>
            <p className={styles["project-description"]}>
                {project.description}
            </p>
            <hr />
            <div className={styles["project-card-bottom"]}>
                <ProjectUserAvatars users={project.users} />
                <div className={styles.information}>
                    <FontAwesomeIcon
                        icon={faCalendar}
                        className={styles.icon}
                    />
                    <p className={styles.data}>{project.date_created}</p>
                </div>
            </div>
        </button>
    );
};

export default ProjectCard;
