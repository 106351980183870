import React from "react";
import styles from "./ProjectNavbox.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectNavButton from "./ui/ProjectNavButton";
import useProjectId from "../hooks/useProjectId";
import { projectNavOptions } from "../constants/generalConstansts";

const ProjectNavbox: React.FC = () => {
    const projectId = useProjectId();

    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path: string) => {
        return location.pathname === `/projects/${projectId}${path}`;
    };
    if (!projectId) {
        return null;
    }
    return (
        <div className={styles["project-nav-box"]}>
            <nav className={styles["nav-bar"]}>
                {projectNavOptions.map((button, id) => {
                    return (
                        <ProjectNavButton
                            name={button.name}
                            icon={button.icon}
                            onClick={() => {
                                navigate(
                                    `/projects/${projectId}${button.path}`,
                                );
                            }}
                            key={id}
                            isActive={isActive(button.path)}
                        />
                    );
                })}
            </nav>
        </div>
    );
};

export default ProjectNavbox;
