import React from "react";
import styles from "./Spinner.module.scss";

const Spinner: React.FC = () => {
    return (
        <div className={styles.background}>
            <span className={styles.loader}></span>
        </div>
    );
};

export default Spinner;
