import React from "react";
import styles from "./Topbar.module.scss";
import LitverseLogo from "./LitverseLogo";
import TopbarUserInformation from "./TopbarUserInformation";

const Topbar: React.FC = () => {
    return (
        <div className={styles["topbar-wrapper"]}>
            <div className={styles.topbar}>
                <LitverseLogo className={styles.logo} />
                <TopbarUserInformation />
            </div>
        </div>
    );
};

export default Topbar;
