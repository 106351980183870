import React from "react";
import ProjectCard from "./ui/ProjectCard";
import styles from "./ProjectGallery.module.scss";
import NewProjectCard from "./ui/NewProjectCard";
import { useProjects } from "../services/providers/ProjectsProvider";
import Spinner from "./ui/Spinner";

const ProjectGallery: React.FC = () => {
    const projects = useProjects();
    return (
        <div className={styles["project-card-grid"]}>
            <NewProjectCard />
            {projects ? (
                projects.map((project, id) => {
                    return <ProjectCard project={project} key={id} />;
                })
            ) : (
                <Spinner />
            )}
        </div>
    );
};

export default ProjectGallery;
