import { useState, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "./UploadProjectCriterionBox.module.scss"; // Ensure this path is correct
import { TextAreaInput } from "./ui/Input";
import UploadMultiInputList from "./UploadMultiInputList";
import { UploadProjectCriteriaOption } from "../constants/generalConstansts";
import { FieldErrors } from "react-hook-form";

interface UploadProjectCriterionBoxProps {
    uploadProjectCriteriaOption: UploadProjectCriteriaOption;
    onChange: (value: any) => void; // This function is from react-hook-form
    onBlur: () => void; // This function is from react-hook-form
    value: any; // This will hold the value managed by react-hook-form
    name: "studyPopulation" | "studyOutcomes" | "studyTopic"; // The name attribute for the form control
    resetValue: () => void; // Function to reset the value
    errors: FieldErrors<{
        studyTopic: string;
        studyPopulation: string;
        studyOutcomes: never[];
    }>;
    invalid: boolean;
    hasChanged: boolean;
}

const UploadProjectCriterionBox = forwardRef<
    HTMLInputElement,
    UploadProjectCriterionBoxProps
>(
    (
        {
            uploadProjectCriteriaOption,
            onChange,
            onBlur,
            value,
            name,
            resetValue,
            invalid,
            errors,
            hasChanged,
        },
        ref,
    ) => {
        const [isOpen, setIsOpen] = useState<boolean>(
            uploadProjectCriteriaOption.required || hasChanged,
        );

        // useEffect(() => {
        //     if (!isOpen) {
        //         resetValue(); // Reset the value when box is closed
        //     }
        // }, [isOpen, resetValue]);

        return (
            <div
                className={`${styles.box} ${isOpen ? styles.open : ""}`}
                onClick={() => {
                    if (!isOpen) {
                        setIsOpen(true);
                    }
                }}
            >
                <div className={styles["info-container"]}>
                    <div className={styles["icon-container"]}>
                        <FontAwesomeIcon
                            icon={
                                !isOpen
                                    ? faPlus
                                    : uploadProjectCriteriaOption.icon
                            }
                            className={styles["icon"]}
                        />
                    </div>
                    <div className={styles["text-container"]}>
                        <p className={styles["criterion-name"]}>
                            {uploadProjectCriteriaOption.name}
                        </p>
                        <p className={styles["criterion-question"]}>
                            {uploadProjectCriteriaOption.question}
                        </p>
                    </div>
                    {isOpen && !uploadProjectCriteriaOption.required && (
                        <div
                            className={styles["delete-icon"]}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the click from bubbling up to the box
                                setIsOpen(false);
                                resetValue();
                            }}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    )}
                </div>
                {isOpen &&
                    (uploadProjectCriteriaOption.multipick ? (
                        <UploadMultiInputList
                            name={name}
                            invalid={invalid}
                            errors={
                                "studyOutcomes" === name
                                    ? errors[name]
                                    : undefined
                            }
                        />
                    ) : (
                        <TextAreaInput
                            // ref={ref}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            invalid={invalid}
                            errorMessage={
                                "studyTopic" === name ||
                                "studyPopulation" === name
                                    ? errors[name]?.message
                                    : undefined
                            }
                        />
                    ))}
            </div>
        );
    },
);

export default UploadProjectCriterionBox;
