import { DecisionType } from "../types/generalTypes";

type StatusType = "reviewing" | "failed" | "completed";
type ColorType = "red" | "yellow" | "green" | "black";

export const projectStatusToColor = (status: StatusType): ColorType => {
    const colorMap: { [key in StatusType]: ColorType } = {
        reviewing: "yellow",
        failed: "red",
        completed: "green",
    };
    return colorMap[status];
};

export const projectStudyDecisionToColor = (
    status: DecisionType,
): ColorType => {
    const colorMap: { [key in DecisionType]: ColorType } = {
        Included: "green",
        Excluded: "red",
        Unclear: "yellow",
        "Invalid Abstract": "black",
    };
    return colorMap[status];
};
