import axios from "axios";
import axiosInstance from "./axiosInstance";
import { ProjectCriterion } from "./fetchProjects";

export interface StudyCriterionResult extends ProjectCriterion {
    percentage: number;
    reasoning: string;
}

const fetchStudyCriteriaResults = async (
    studyId: number,
    projectId: number,
): Promise<StudyCriterionResult[]> => {
    try {
        const response = await axiosInstance.get<StudyCriterionResult[]>(
            `/projects/${projectId}/studies/${studyId}/results`,
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default fetchStudyCriteriaResults;
