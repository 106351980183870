import axios from "axios";
import axiosInstance from "./axiosInstance";
import { DecisionType } from "../../types/generalTypes";
import { SortOption, StudyFilters } from "../providers/ProjectStudiesProvider";

export interface ProjectStudy {
    study_id: number;
    project_id: string;
    title: string;
    abstract: string | null;
    authors: string[] | null;
    year: number | null;
    citations: number | null;
    percentage: number | null;
    decision: DecisionType;
    rank: number | null;
}

export interface DistinctCountElement {
    filter_variables: {
        decision: DecisionType;
        year: number | null;
        citations: number | null;
    };
    count: number;
    total_studies: number;
}

export interface ProjectStudyData {
    project_studies: ProjectStudy[];
    distinct_counts: DistinctCountElement[];
    total_studies: number;
}

const fetchProjectStudies = async (
    projectId: number,
    studyFilters: StudyFilters,
    page: number,
    sortOption: SortOption,
    searchString: string | null,
): Promise<ProjectStudyData> => {
    try {
        const response = await axiosInstance.post<ProjectStudyData>(
            `/projects/${projectId}/studies`,
            {
                study_filters: studyFilters,
                page: page,
                sort_option: {
                    variable: sortOption.variable,
                    order: sortOption.order,
                },
                search_string: searchString,
            },
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("API call failed:", error.response?.data);
        } else {
            console.error("An unexpected error occurred:", error);
        }
        throw error;
    }
};

export default fetchProjectStudies;
