import { useSelectedStudyData } from "../services/providers/StudyDataProvider";
import { projectStudyDecisionToColor } from "../utils/statusToColorMapping";
import ProjectStudyAuthors from "./ProjectStudyAuthors";
import ProjectStudyCitations from "./ProjectStudyCitations";
import styles from "./ProjectStudyModalProperties.module.scss";
import ProjectStudyYear from "./ProjectStudyYear";
import StatusIndicator from "./ui/StatusIndicator";

const ProjectStudyModalProperties: React.FC = () => {
    const selectedStudy = useSelectedStudyData();
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <p className={styles.title}>{selectedStudy.title}</p>
                <div className={styles.information}>
                    <ProjectStudyAuthors
                        authors={selectedStudy.authors}
                        showAll={true}
                        big={true}
                    />
                    <ProjectStudyYear year={selectedStudy.year} big={true} />
                    <ProjectStudyCitations
                        citations={selectedStudy.citations}
                        big={true}
                    />
                </div>
            </div>
            <div className={styles.right}>
                {selectedStudy.rank !== null && (
                    <p className={styles["rank"]}>{`#${selectedStudy.rank}`}</p>
                )}
                {selectedStudy.percentage !== null && (
                    <p
                        className={styles["percentage"]}
                    >{`${Math.round(selectedStudy.percentage * 100)}%`}</p>
                )}
                <StatusIndicator
                    text={selectedStudy.decision}
                    color={projectStudyDecisionToColor(selectedStudy.decision)}
                    size="large"
                />
            </div>
        </div>
    );
};

export default ProjectStudyModalProperties;
